import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthType } from '../enums/Auth';
import { AppConfig, APP_CONFIG } from '../modules/config/app-configuration';
import { nextTick } from 'q';
import { User } from '../classes/auth/User';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private user: UserService,
		private auth: AuthService,
		private router: Router,
		@Inject(APP_CONFIG) private config: AppConfig
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (next.data.auth !== undefined) {
			if (!this.validateUser(next.data.auth, this.user.current)) {
				if (next.data.auth !== AuthType.NotLoggedIn) {
					this.auth.setRef(next); // save for when he logs in
					this.router.navigate([this.config.APP_LOGIN_PATH]);
				}
				return false;
			}
		}
		return true;
	}

	private validateUser(authType: AuthType, user: User) {
		switch (authType) {
			case AuthType.NotLoggedIn:
				return user === undefined || !user;
			case AuthType.User:
				return !!user;
			case AuthType.SuperAdmin:
				return user && user.isSuper;
		}
	}
}
