import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'slashIfEmpty'
})
export class SlashIfEmptyPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		if (!value) {
			return '/';
		}
		return value;
	}

}
