import { Injectable, OnDestroy, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { map, tap } from 'rxjs/operators';
import { ALLOW_COOKIES_CONFIG, AllowCookiesModuleConfig } from '../modules/config/app-configuration';

@Injectable({
	providedIn: 'root'
})
export class AllowCookiesService {

	accepted$: Observable<boolean>;

	cookieValue$: BehaviorSubject<string>;

	show$: BehaviorSubject<boolean>;

	// inside cookies
	private get cookieValue() {
		const val =  this.ngxCookie.get(this.COOKIES_CONFIG.COOKIENAME);
		if (typeof val !== 'undefined' && val === this.COOKIES_CONFIG.ACCEPTED) {
			return this.COOKIES_CONFIG.ACCEPTED;
		}
		return this.COOKIES_CONFIG.DECLINED;
	}

	constructor(
		@Inject(ALLOW_COOKIES_CONFIG) private COOKIES_CONFIG: AllowCookiesModuleConfig,
		private ngxCookie: CookieService
	) {
		// observable for cookie change
		this.cookieValue$ = new BehaviorSubject(this.cookieValue);

		// tap it ito cookies and map to accepted true/false
		this.accepted$ = this.cookieValue$.pipe(
			tap(val => this.ngxCookie.set(this.COOKIES_CONFIG.COOKIENAME, val)),
			map(val => val === this.COOKIES_CONFIG.ACCEPTED ? true : false)
		);

		this.show$ = new BehaviorSubject(this.cookieValue$.getValue() === '1');
	}

	toggle() {
		this.show$.next(!this.show$.getValue());
	}

	accept() {
		this.cookieValue$.next(this.COOKIES_CONFIG.ACCEPTED);
		this.toggle();
	}
	decline() {
		this.cookieValue$.next(this.COOKIES_CONFIG.DECLINED);
		this.toggle();
	}
}
