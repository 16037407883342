import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ViewChildren, AfterViewInit, QueryList, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

@Component({
	selector: 'app-input-text',
	templateUrl: './input-text.component.html',
	styleUrls: ['./input-text.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InputTextComponent implements OnInit, AfterViewInit {


	private _value: any;

	@Output()
	valueChange = new EventEmitter<any>();

	@Input()
	set value(val: any) {
		this._value = val;
		this.valueChange.emit(val);
	}
	get value() {
		return this._value;
	}

	@Input()
	name: string;

	@Input()
	label = '';

	@Input()
	disabled: boolean;

	@Input()
	maxlength = 255;

	@Input()
	required = false;

	@Input()
	type = 'string';

	@Input()
	textArea = false;

	@ViewChildren('field')
	fields: QueryList<NgModel>;

	constructor(
		private form: NgForm,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit() {

	}

	ngAfterViewInit(): void {
		this.cdr.detectChanges();
	}


}
