import { Component, Injector, OnInit } from '@angular/core';
import { ConfigBasedComponent } from 'src/app/classes/base/ConfigBasedComponent';
import { CreateAccount } from 'src/app/interface/Auth';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-create-account',
	templateUrl: './create-account.component.html',
	styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent extends ConfigBasedComponent implements OnInit {
	// set email once in
	account: CreateAccount = {
		email: '',
		password: '',
		confirm: '',
		token: ''
	};
	constructor(
		private auth: AuthService,
		inj: Injector
	) {
		super(inj);
	}

	ngOnInit() {
		this.account.email = this.route.snapshot.data.email.data;
		this.account.token = this.route.snapshot.params['token'];
	}

	createAccount() {
		this.auth.createAccount(this.account);
	}

}
