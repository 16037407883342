import { Component, OnInit, Injector } from '@angular/core';
import { FormsConfig, FORMS_CONFIG } from 'src/app/modules/config/app-configuration';
import { UserLogin } from 'src/app/classes/Auth';
import { AuthService } from 'src/app/services/auth.service';
import { JwtService } from 'src/app/services/jwt.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	formsConfig: FormsConfig;
	user: UserLogin = {
		email: '',
		password: ''
	};

	constructor(
		private auth: AuthService,
		inj: Injector,
		private router: Router,
		private jwt: JwtService
	) {
		this.formsConfig = inj.get(FORMS_CONFIG);
	}

	login() {
		this.auth.login(this.user).then(
			(res) => {
				this.router.navigate(['/crm/']);
			}
		);
	}
}
