import { ArcturHelper } from '../helpers/ArcturHelper';

export class DbModel {
	id?: string;

	setFromDb(obj?: any) {
		if (obj) {
			for (const key of Object.keys(obj)) {
				if (key.startsWith('dateTime')) {
					this[key] = ArcturHelper.fromUtcDateTime(obj[key]);
					continue;
				}
				if (key.startsWith('date')) {
					this[key] = ArcturHelper.fromLocalDateTime(obj[key]);
					continue;
				}
				this[key] = obj[key];
			}
		}
	}
	cloneForDb() {
		const clone = Object.assign({}, this);
		for (const key of Object.keys(clone)) {
			if (key.startsWith('date')) {
				clone[key] = ArcturHelper.makeLocalDateAsUtc(clone[key]);
				continue;
			}
		}
		return clone;
	}
}
