import { ConfirmationService } from 'primeng/primeng';
import { BaseInjector } from '../classes/BaseInjector';
import { DbModel } from '../classes/base/DbModel';

export function ArcturToDbValues(
): MethodDecorator {
	return function (target: Function, key: string, descriptor: any) {

		const originalMethod = descriptor.value;
		descriptor.value = function (...args: any[]) {

			const newArgs = args.map(a => {
				if (a instanceof DbModel) {
					return a.cloneForDb();
				}
				return a;
			});
			return originalMethod.apply(this, newArgs);
		};
	};
}
