export class ArcWebCam {
	/**
 * Lists available videoInput devices
 * @returns a list of media device info.
 */
	public static getAvailableVideoInputs(): Promise<MediaDeviceInfo[]> {
		if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
			return Promise.reject('enumerateDevices() not supported.');
		}

		return new Promise((resolve, reject) => {
			const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
			if (iOS) {
				navigator.mediaDevices.getUserMedia({video: {facingMode: 'user'}, })
					.then(stream => {
						navigator.mediaDevices.enumerateDevices()
						.then((devices: MediaDeviceInfo[]) => {
							resolve(devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput'));
						})
						.catch(err => {
							reject(err.message || err);
						});
					})
					.catch((err) => console.log(err));
			} else {
				navigator.mediaDevices.enumerateDevices()
				.then((devices: MediaDeviceInfo[]) => {
					resolve(devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput'));
				})
				.catch(err => {
					reject(err.message || err);
				});
			}
		});
	}
}
