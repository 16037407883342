import { Component, OnInit, Input, Injector } from '@angular/core';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { CacheService } from 'src/app/services/cache.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-filter-search',
	templateUrl: './filter-search.component.html',
	styleUrls: ['./filter-search.component.scss']
})
export class FilterSearchComponent implements OnInit {
	categoryFeed: CheckItem[];
	selectedCategories = [];
	searchValue: string;
	cache: any;

	constructor(
		inj: Injector,
		private contactsService: ContactsService,
		private router: Router,
		private cacheService: CacheService
	) {
		this.cache = inj.get(CacheService);
		//this.selectedCategories = this.cache.cache.categories ? this.cache.cache.categories : [];
		//this.searchValue = this.cache.cache.searchString ? this.cache.cache.searchString : '';
	 }

	ngOnInit() {
		this.contactsService.getCategory()
			.then(
				(category: CheckItem[]) => {
					this.categoryFeed = category;
				}
			);
		const searchCache = this.cacheService.getCache();
		if(searchCache && searchCache.searchString !== ''){
			this.searchValue = searchCache.searchString;
		}
	}

	search(){
		this.cache.setCache(this.selectedCategories,this.searchValue);
		this.cache.refresh$.next(true);
		this.router.navigate(['/crm/contacts/list']);
	}

	setCache(){
		this.cache.categories = this.selectedCategories;
		this.search();
	}

	eventHandler(keycode){
		if(keycode === 13){
			this.search();
		}
	}

}
