import {
	HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse,
	HttpProgressEvent, HttpResponse, HttpUserEvent, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { JwtService } from 'src/app/services/jwt.service';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from './app-configuration';
import { Inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

	constructor(
		private jwtService: JwtService,
		private user: UserService,
		private router: Router,
		private messageService: MessageService,
		@Inject(APP_CONFIG) private config: AppConfig
	) { }



	// tslint:disable-next-line:max-line-length
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

		const headers = {};

		if (this.jwtService.jwt !== undefined && this.jwtService.jwt) {
			headers[this.config.JWT_HEADER] = this.jwtService.jwt;
		}

		req = req.clone({
			setHeaders: headers
		});

		return next.handle(req).pipe(
			// check jwts and handle them
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {

					// if jwt exists then add it to jwt service
					const jwt = event.headers.get(this.config.JWT_HEADER);
					if (jwt) {
						this.jwtService.jwt = jwt;
					}
					if (event.body && event.body.status) {
						const serverStatus = event.body.status;
						if (serverStatus) {
							this.messageService.add({
								severity: 'success',
								summary: serverStatus
							});
						}
					}
				}
			}, err => {
				if (err instanceof HttpErrorResponse) {
					const errObj = <HttpErrorResponse>err;

					// nabavni server status
					const serverStatus = errObj.error.status;
					if (serverStatus) {
						this.messageService.add({
							severity: 'error',
							summary: serverStatus
						});
					}

					switch (err.status) {
						case 401:
							const errData = err.error.data;
							let redirect = '/auth/login';
							switch (errData.token) {
								case this.config.JWT_HEADER:
									if (errData.deleteToken) {
										this.jwtService.jwt = null;
										this.user.current = null;
									}
									redirect = errData.redirectTo ? errData.redirectTo : redirect;
									this.router.navigate([redirect]);
									break;
							}
							break;
					}
				}
			}),
			// extract status from body which is for growl and
			// just send data further
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					if (event.body && event.body.data) {
						event = event.clone({body: event.body.data});
					}
				}
				return event;
			})
		);
	}
}
