import { InjectionToken } from '@angular/core';
import { CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';

import baseTranslations from './base-translations.json';

const apiPath = '/api/v1';
const loginPath = '/auth/login';

export interface AppConfig {
	API_PATH: string;
	APP_LOGIN_PATH: string;
	JWT_HEADER: string;
	SHOW_COOKIES: boolean;
}
export const AppConfig: AppConfig = {
	API_PATH: apiPath,
	APP_LOGIN_PATH: loginPath,
	JWT_HEADER: 'Bearer-One',
	SHOW_COOKIES: true
};
export const APP_CONFIG = new InjectionToken<AppConfig>('application configuration');

export interface TableConfig {
	rows: number;
	paginator: boolean;
	emptyMessage: string;
	dropdown: { [key: string]: string | boolean };
}
export const TableConfig: TableConfig = {
	rows: 1,
	paginator: true,
	emptyMessage: 'Ni rezultatov',
	dropdown: {
		autoWidth: false
	}
};
export const TABLE_CONFIG = new InjectionToken<TableConfig>('application configuration');

export const NabavniCurrencyMaskConfig: CurrencyMaskConfig = {
	align: 'left',
	allowNegative: false,
	decimal: ',',
	precision: 2,
	prefix: '€ ',
	suffix: '',
	thousands: '.'
};

export interface FormsConfig {
	CONFIG: {[key: string]: string|number};
	TRANSLATE_ERRORS: boolean;
	BASE_VALIDATION_ERRORS: {[key: string]: string};
	ERRORS_REPLACE_VALUES: {[key: string]: string[]};
}
export const FormsConfig: FormsConfig = {
	CONFIG: {
		'password_length': 6
	},
	TRANSLATE_ERRORS: false,
	BASE_VALIDATION_ERRORS: {
		'form_error_required': 'Obvezno polje',
		'form_error_minlength': 'Geslo mora vsebovati vsaj requiredLength znakov',
		'form_error_equalPassword': 'Vnešeni gesli se ne ujemata'
	},
	ERRORS_REPLACE_VALUES: {
		'form_error_minlength': ['requiredLength']
	}
};
export const FORMS_CONFIG = new InjectionToken<FormsConfig>('FormsConfig');

export interface TranslateConfig {
	USE_TRANSLATIONS: boolean;
	BASE_LABELS: {[key: string]: string};
}
export const TranslateConfig: TranslateConfig = {
	USE_TRANSLATIONS: false,
	BASE_LABELS: baseTranslations
};
export const TRANSLATE_CONFIG = new InjectionToken<TranslateConfig>('TranslateConfig');

export interface AllowCookiesModuleConfig {
	COOKIENAME: string;
	ACCEPTED: string;
	DECLINED: string;
}

export const ALLOW_COOKIES_CONFIG = new InjectionToken<AllowCookiesModuleConfig>('application configuration');
