import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactsService } from 'src/app/services/contacts.service';
import { Contacts } from 'src/app/classes/crm/contacts';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-contacts-detail',
	templateUrl: './contacts-detail.component.html',
	styleUrls: ['./contacts-detail.component.scss']
})
export class ContactsDetailComponent implements OnInit {
	contactDetail: Contacts;
	categoryFeed: CheckItem[];
	results: string[];
	label = '';
	baseUrl = '/crm/contacts';
	mmaUrl = 'crm/imageupload';
	submitLabel = 'DODAJ';	

	@ViewChild('formModule')
	contactForm: NgForm;

	constructor(
		private contactsService: ContactsService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) { }

	get canLeave(){
		return this.contactForm.pristine;
	}

	ngOnInit() {
		this.contactsService.getCategory()
			.then(
				(category: CheckItem[]) => {
					this.categoryFeed = category;
				}
			);
		this.activatedRoute.params.subscribe(params => {
			if (params.id !== undefined) {
				this.contactsService.getContactsDetail(params.id)
					.then(
						(contacts: Contacts) => {
							this.contactDetail = contacts;
							this.submitLabel = 'SHRANI';
							//this.mmaUrl += '/' + params.id;
						}
					);
			} else {
				this.contactDetail = new Contacts();
			}
		});
	} 

	callSave() {
		this.contactsService.save(this.contactDetail)
			.then(
				(data: Contacts) => {
					//this.router.navigate([this.baseUrl, 'show', data]);
					this.contactForm.resetForm(this.contactDetail);
					this.submitLabel = 'SHRANI';
				}
			)
	}

	addLabel(){
		let label = 'DODAJ';
		this.activatedRoute.params.subscribe(params => {
			if(params['id'] !== undefined){
				label = 'SHRANI';
			}
			
		});
		return label;
		
	}

	search(event) {
		const queryObj = { 'search': event.query }
		this.contactsService.getResults(queryObj).then(data => {
			this.results = data;
		});
	}


}
