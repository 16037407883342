import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-input-select',
	templateUrl: './input-select.component.html',
	styleUrls: ['./input-select.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class InputSelectComponent implements OnInit {

	private _value: any;

	@Output()
	valueChange = new EventEmitter<any>();

	@Input()
	set value(val: any) {
		this._value = val;
		this.valueChange.emit(val);
	}

	get value() {
		return this._value;
	}

	@Input()
	name: string;

	@Input()
	label: string;

	@Input()
	options: any[];

	@Input()
	disabled: boolean;

	@Input()
	required: boolean;

	@Input()
	showLabel = false;

	@Input()
	optionLabel = null;

	@Input()
	dataKey = null;

	@Input()
	filter = true;


	constructor() { }

	ngOnInit() {
	}

}
