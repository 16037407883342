import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PipesModule } from 'src/app/modules/pipes/pipes.module';
import { ProvidersModule } from 'src/app/modules/providers/providers.module';
import { FormsModule } from '@angular/forms';
import { AppRouterModule } from 'src/app/modules/router/app-router.module';
import { HttpClientModule } from '@angular/common/http';
import { AppConfigModule } from '../config/app-config.module';
import { PrimeNgModule } from '../prime-ng/prime-ng.module';
import { DirectivesModule } from '../directives/directives.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AllowCookiesModule } from '../allow-cookies/allow-cookies.module';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		AppRouterModule,
		AppConfigModule,
		PrimeNgModule,
		PipesModule,
		ProvidersModule,
		DirectivesModule,
		NgxWebstorageModule.forRoot(),
		AllowCookiesModule.forRoot(),
		NgxPasswordToggleModule
	],
	exports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		AppRouterModule,
		AppConfigModule,
		PrimeNgModule,
		PipesModule,
		ProvidersModule,
		DirectivesModule,
		NgxWebstorageModule,
		AllowCookiesModule,
		NgxPasswordToggleModule
	]
})
export class SharedModule { }
