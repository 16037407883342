import { NgModule, Injector } from '@angular/core';
import { AppComponent } from './app.component';
import { ComponentsModule } from './modules/components/components.module';
import { ProvidersModule } from './modules/providers/providers.module';
import { BaseInjector } from './classes/BaseInjector';
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { TranslateModule } from 'arctur-translate-all';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxVcardModule } from 'ngx-vcard';


@NgModule({
	imports: [
		ComponentsModule,
		ProvidersModule,
		ClipboardModule,
		NgxVcardModule,
		DeviceDetectorModule.forRoot(),
		TranslateModule.forRoot({
			app: 'scmms',       // okrajsava projekta
			devHosts: [       // urlji, kjer bo onemogocen cache
				'test-cloud-a.4pm.si',
				'test-cloud-b.4pm.si',
				'devel-cloud-a.4pm.si',
				'devel-cloud-b.4pm.si'
			]
		}),
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
	],
	declarations: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(
		private inj: Injector
	) {
		BaseInjector.injector = this.inj;
	}
}
