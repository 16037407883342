import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { JwtService } from 'src/app/services/jwt.service';
import { UserService } from 'src/app/services/user.service';
import { MessageService, ConfirmationService } from 'primeng/primeng';
import { JwtResolve } from 'src/app/resolvers/jwt-resolve.service';
import { CreateAccTokenResolve } from 'src/app/resolvers/create-acc-token-resolver.service';
import { CookieService } from 'ngx-cookie-service';
import { Logger } from 'arctur-logger';

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		AuthService,
		JwtService,
		UserService,
		MessageService,
		JwtResolve,
		CreateAccTokenResolve,
		ConfirmationService,
		CookieService,
		Logger
	]
})
export class ProvidersModule { }
