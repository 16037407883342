import { Injectable, Injector } from '@angular/core';
import { DbService } from '../classes/base/DbService';
import { Contacts } from '../classes/crm/contacts';
import { CheckItem } from '../classes/base/CheckItem';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ContactsService extends DbService<Contacts> {
	apiPath = 'crm/contacts';
	type = Contacts;

	constructor(
		inj: Injector
	) {
		super(inj);
	}

	getCategory(): Promise<CheckItem[]> {

		return this.http.get<CheckItem[]>(`${this.config.API_PATH}/${this.apiPath}-category-list/`)
			.toPromise();
	}

	getContactCategory(): Promise<any[]> {

		return this.http.get<any>(`${this.config.API_PATH}/crm/contacts-by-category/`)
			.toPromise();
	}

	getContacts(id: string): Promise<Contacts[]> {

		return this.http.get<Contacts[]>(`${this.config.API_PATH}/${this.apiPath}-list/`)
			.toPromise();
	}

	getCachedContacts(): Promise<any[]> {

		return this.http.get<any[]>(`${this.config.API_PATH}/${this.apiPath}-cached/`)
			.toPromise();
	}

	searchContacts(filter: any): Observable<Contacts[]> {
		const searchFilter = btoa(encodeURI(JSON.stringify(filter)));
		return this.http.get<Contacts[]>(`${this.config.API_PATH}/${this.apiPath}-search/?filter=${searchFilter}`);
	}

	getCategorys(id: string): Promise<CheckItem[]> {

		return this.http.get<CheckItem[]>(`${this.config.API_PATH}/${this.apiPath}-category/${id}`)
			.toPromise();
	}

	getContactsDetail(id: string): Promise<Contacts> {

		return this.http.get<Contacts>(`${this.config.API_PATH}/${this.apiPath}-detail/${id}`)
			.toPromise();
	}
	getContactsByCategory(obj: any[]): Promise<Contacts[]> {

		return this.http.post<Contacts[]>(`${this.config.API_PATH}/${this.apiPath}-by-categoy/`,obj)
			.toPromise();
	}

	insertUpdateCategorys(obj: CheckItem){
		return this.http.post<CheckItem>(`${this.config.API_PATH}/${this.apiPath}-categoy/`, obj)
			.toPromise();
	}

	getResults(search: any){
		return this.http.post<any>(`${this.config.API_PATH}/${this.apiPath}-company-filter/`, search)
			.toPromise();
	}
	deleteCategory(id: string){
		return this.http.delete<any>(`${this.config.API_PATH}/${this.apiPath}-categoy-delete/${id}/`)
			.toPromise();
	}

	uploadImages(obj: any): Promise<any[]> {

		return this.http.post<any[]>(`${this.config.API_PATH}/crm/upload-images/`,obj)
			.toPromise();
	}

}
