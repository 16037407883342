import { DbModel } from "../base/DbModel";

export class Ocr extends DbModel {

	constructor(obj?: any) {
		super();
		// if using super then
		// if you set default values in child properties
		// it gets overriden
		this.setFromDb(obj);
	}

}
