import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ConfigBasedComponent } from '../classes/base/ConfigBasedComponent';

@Injectable({
	providedIn: 'root'
})
export class CanDeactivateService implements CanDeactivate<any> {

	constructor(
		private confirmService: ConfirmationService
	) { }

	canDeactivate(component: ConfigBasedComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot):
			Observable<boolean> | Promise<boolean> | boolean {
		// code
		console.log(component);
		if (component === null || component.canLeave) {
			console.log('is true');
			return true;
		} else {
			return Observable.create((observer: Observer<boolean>) => {
				this.confirmService.confirm({
					header: 'Zavrži spremembe',
					message: 'Če zapustite trenutno okno boste izgubili vse neshranjene spremembe. <br/> Ali želite nadaljevati?',
					icon: 'fa fa-exclamation',
					accept: () => {
						observer.next(true);
						observer.complete();
					},
					reject: () => {
						observer.next(false);
						observer.complete();
					}
				});
			})
		}
	}


}
