import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cutAt'
})
export class CutAtPipe implements PipeTransform {

	transform(value: any, length?: any): any {
		if (value.length <= length) {
			return value;
		}

		return (<string>value).substring(0, 20) + ' ...';
	}

}
