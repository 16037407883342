import { NgModule } from '@angular/core';
import { EqualPasswordDirective } from 'src/app/directives/equal-password.directive';
import { ArcturPrimengTableDirective } from 'src/app/directives/arctur-primeng-table.directive';

@NgModule({
	imports: [],
	declarations: [
		EqualPasswordDirective,
		ArcturPrimengTableDirective
	],
	exports: [
		EqualPasswordDirective,
		ArcturPrimengTableDirective
	]
})
export class DirectivesModule { }
