import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[appEqualPassword]',
	providers: [
		{ provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualPasswordDirective), multi: true }
	]
})
export class EqualPasswordDirective implements Validator {

	@Input('appEqualPassword')
	public appEqualPassword: string;

	// because the error should reflect at the confirm and not in the current password
	@Input('appReverseCheck')
	public appReverseCheck: string;

	constructor() { }
	validate(c: AbstractControl): { [key: string]: any; } {
		// self value
		const v = c.value;

		// control vlaue
		const e = c.root.get(this.appEqualPassword);

		// value not equal
		if (e && v !== e.value && !this.checkIfReverse) {
			return {
				equalPassword: true
			};
		}
		// value equal and reverse
		if (e && v === e.value && this.checkIfReverse) {
			delete e.errors['equalPassword'];
			if (!Object.keys(e.errors).length) {
				e.setErrors(null);
			}
		}

		// value not equal and reverse
		if (e && v !== e.value && this.checkIfReverse) {
			e.setErrors({ equalPassword: true });
		}

		return null;
	}

	private get checkIfReverse() {
		if (this.appReverseCheck === undefined) {
			return false;
		}
		return true;
	}
}
