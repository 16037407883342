import { Injectable, OnDestroy } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Contacts } from '../classes/crm/contacts';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { ContactsService } from './contacts.service';
import { share, switchMap } from 'rxjs/operators';
import { WebcamImage } from 'ngx-webcam';
import { NgxIndexedDB } from 'ngx-indexed-db';

@Injectable({
	providedIn: 'root'
})
export class CacheService implements OnDestroy {


	@LocalStorage()
	cache: any;

	@LocalStorage()
	private _scanedImages: any;

	public notEditedImages = {
		notEditedList: []
	};

	get scanedImages() {
		return this._scanedImages;
	}


	refresh$ = new BehaviorSubject(false);



	searchResults$: Observable<Contacts[]> =
		this.refresh$.pipe(
			switchMap(r => this.contactsService.searchContacts(this.cache)),
			share()
		);


	// onDestroy
	onDestroy$: Subject<boolean>;

	constructor(
		private contactsService: ContactsService
	) {
		if (!this.scanedImages) {
			this.resetScanedImages();
		}
		if (!this.cache) {
			this.resetCache();
		}
		// this.searchResults$ = new BehaviorSubject(null);
	}

	private resetCache() {
		this.cache = {
			categories: [],
			searchString: ''
		};
	}
	private resetScanedImages() {
		this._scanedImages = [];
	}

	setCache(
		categorys: [],
		searchString: string
	) {
		this.cache = {
			categories: categorys,
			searchString: searchString
		};
	}

	setScanedImages(
		scanedImage: WebcamImage
	) {
		let tmp = [];
		if(this.scanedImages.length > 0){
			tmp = this.scanedImages;
		}
		tmp.push(scanedImage);
		this._scanedImages = tmp;
	}

	removeScanedImage(index: string){
		let tmp = this._scanedImages;
		tmp.splice(index);
		this._scanedImages = tmp;
	}



	getCache() {
		return this.cache;
	}



	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
