import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';

@Injectable({
	providedIn: 'root'
})
export class JwtResolve implements Resolve<void> {

	constructor(
		private authService: AuthService,
		private jwtService: JwtService,
		private router: Router
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void | Observable<void> | Promise<void> {
		const jwt: string = route.paramMap.get('jwt');
		this.jwtService.jwt = jwt;
		return this.authService.validateJwt()
			.catch( err => {
				this.jwtService.jwt = null;
				this.router.navigate(['/auth/forgot-password']);
			});
	}
}
