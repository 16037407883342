export class File {
	id?: string;
	fileSize?: string;
	fileNameStored?: string;
	fileName?: string;
	fileType?: string;
	lng?: string;

	get path() {
		return `/mma/${this.fileNameStored}/${this.id}/`;
	}

	constructor(obj?: any) {
		if (obj) {
			for (const key of Object.keys(obj)) {
				this[key] = obj[key];
			}
		}
	}
}