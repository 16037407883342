import { Directive, Inject } from '@angular/core';
import { TABLE_CONFIG, TableConfig } from '../modules/config/app-configuration';
import { Table } from 'primeng/table';

@Directive({
	selector: '[appArcturPrimengTable], p-table'
})
export class ArcturPrimengTableDirective {
	constructor(
		private dt: Table,
		@Inject(TABLE_CONFIG) private tableConfig: TableConfig
	) {
		this.dt.rows = this.tableConfig.rows;
		this.dt.paginator = this.tableConfig.paginator;
		this.dt.filterConstraints['dateDay'] = function dateDay(value: Date, filter: Date) {
			// value = array of data from the current row
			// filter = value from the filter that will be searched in the value-array

			if (filter === undefined || filter === null) {
				return true;
			}

			if (value === undefined || value === null) {
				return false;
			}

			const dateValue = new Date(value);
			const dateFilter = new Date(filter);

			if (dateValue.getFullYear() == dateFilter.getFullYear()
				&& dateValue.getMonth() == dateFilter.getMonth()
				&& dateValue.getDate() == dateFilter.getDate()) {
				return true;
			}
			return false;
		};
		this.dt.filterConstraints['dateFrom'] = function dateFrom(value: Date, filter: Date) {
			// value = array of data from the current row
			// filter = value from the filter that will be searched in the value-array

			if (filter === undefined || filter === null) {
				return true;
			}

			if (value === undefined || value === null) {
				return false;
			}

			const dateValue = new Date(value);
			const dateFilter = new Date(filter);

			if (dateValue.getFullYear() >= dateFilter.getFullYear()
				&& dateValue.getMonth() >= dateFilter.getMonth()
				&& dateValue.getDate() >= dateFilter.getDate()) {
				return true;
			}
			return false;
		};
		this.dt.filterConstraints['dateTo'] = function dateFrom(value: Date, filter: Date) {
			// value = array of data from the current row
			// filter = value from the filter that will be searched in the value-array

			if (filter === undefined || filter === null) {
				return true;
			}

			if (value === undefined || value === null) {
				return false;
			}

			const dateValue = new Date(value);
			const dateFilter = new Date(filter);

			if (dateValue.getFullYear() <= dateFilter.getFullYear()
				&& dateValue.getMonth() <= dateFilter.getMonth()
				&& dateValue.getDate() <= dateFilter.getDate()) {
				return true;
			}
			return false;
		};
	}
}
