import { Injectable, Injector } from '@angular/core';
import { ConfigBasedHttpService } from '../classes/base/ConfigBasedService';
import { About } from '../interface/admin/About';
import { ApplicationUser } from '../classes/admin/ApplicationUser';
import { map } from 'rxjs/operators';
import { SetPassword } from '../interface/Auth';

@Injectable({
	providedIn: 'root'
})
export class AdminService extends ConfigBasedHttpService {

	constructor(
		inj: Injector
	) {
		super(inj);
	}

	getUsers(): Promise<ApplicationUser[]> {
		return this.http.get<Object[]>(`${this.config.API_PATH}/admin/users`)
			.pipe(
				map(users => users.map(u => new ApplicationUser(u)))
			)
			.toPromise();
	}

	getUser(id: string): Promise<ApplicationUser> {
		if (!id) {
			return new Promise((res, rej) => res(ApplicationUser.create()));
		}
		return this.http.get<Object>(`${this.config.API_PATH}/admin/user/${id}`)
			.pipe(
				map(u => new ApplicationUser(u))
			).toPromise();
	}

	activateUser(user: ApplicationUser): Promise<any> {
		return this.http.post(`${this.config.API_PATH}/admin/activate-user`, {id: user.id})
			.toPromise();
	}
	deactivateUser(user: ApplicationUser): Promise<any> {
		return this.http.post(`${this.config.API_PATH}/admin/deactivate-user`, {id: user.id})
			.toPromise();
	}

	promoteToSuper(user: ApplicationUser) {
		return this.http.post(`${this.config.API_PATH}/admin/promote-to-super`, {id: user.id})
			.toPromise();
	}
	demoteFromSuper(user: ApplicationUser) {
		return this.http.post(`${this.config.API_PATH}/admin/demote-from-super`, {id: user.id})
			.toPromise();
	}

	// returns id
	saveUser(user: ApplicationUser): Promise<string> {
		if (user.id) {
			return this.http.put<string>(`${this.config.API_PATH}/admin/user`, user)
				.toPromise();
		}
		return this.http.post<string>(`${this.config.API_PATH}/admin/user`, user)
			.toPromise();
	}

	savePassword(user: ApplicationUser, changePassword: SetPassword) {
		return this.http.post(`${this.config.API_PATH}/admin/change-usr-pwd`, {...changePassword, id: user.id, email: user.email})
			.toPromise();
	}


	// -- ABOUT -- //

	getAbout() {
		return this.http.get<About>(`${this.config.API_PATH}/admin/about`)
			.toPromise();
	}

	saveAbout(about: About) {
		return this.http.post<About>(`${this.config.API_PATH}/admin/about`, about)
			.toPromise();
	}
}
