import { Component, OnInit, Injector } from '@angular/core';
import { ConfigBasedComponent } from 'src/app/classes/base/ConfigBasedComponent';
import { About } from 'src/app/interface/admin/About';
import { AdminService } from 'src/app/services/admin.service';

@Component({
	selector: 'app-admin-about',
	templateUrl: './admin-about.component.html',
	styleUrls: ['./admin-about.component.scss']
})
export class AdminAboutComponent extends ConfigBasedComponent implements OnInit {

	about: About;

	constructor(
		private admin: AdminService,
		inj: Injector
	) {
		super(inj);
		this.admin.getAbout()
			.then(a => this.about = a);
	}

	ngOnInit() {
	}

	save() {
		this.admin.saveAbout(this.about);
	}

}
