import { Injectable, Injector } from '@angular/core';
import { ConfigBasedService } from '../classes/base/ConfigBasedService';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class FilesService extends ConfigBasedService {

	constructor(
		private http: HttpClient,
		inj: Injector
	) {
		super(inj);
	}

	deleteFile(path: string) {
		return this.http.delete<void>(`${this.config.API_PATH}/${path}`)
			.toPromise();
	}

	updateDocLanguage(obj: any) {
		return this.http.put<any>(`${this.config.API_PATH}/project-partners/update-file-lng/`,obj)
			.toPromise();
	}
}
