export class ArcturHelper {
	static fromUtcDateTime(datestring: string): Date {
		// const date = new Date(datestring);
		// const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

		// const offset = date.getTimezoneOffset() / 60;
		// const hours = date.getHours();

		// newDate.setHours(hours - offset);

		// UTC ustrezno pretvori
		return new Date(`${datestring} UTC`);
	}
	static fromLocalDateTime(datestring: string): Date {
		// const date = new Date(datestring);
		// const utcTimestamp =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
		// date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
		// const newDate = new Date(utcTimestamp);
		// return newDate;

		// var dateFromPicker = "2012-10-12";
		// var timeFromPicker = "12:30";

		const explode = datestring.split(' ');

		const datePart = explode[0];
		const timePart = explode[1] ? explode[1] : null;

		const dateParts = datePart.split('-').map(p => parseInt(p, 10));
		const timeParts = timePart ? timePart.split(':').map(t => parseInt(t, 10)) : null;

		// ta nacin vedno ustvari kot local time
		return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts ? timeParts[0] : null, timeParts ? timeParts[1] : null);
	}

	static makeLocalDateAsUtc(date: Date) {
		return new Date(Date.UTC(
			date.getFullYear(),
			date.getMonth(),
			date.getDate()
			, 0, 0, 0));
	}
}
