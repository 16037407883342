import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
	APP_CONFIG,
	AppConfig,
	FORMS_CONFIG,
	FormsConfig,
	TableConfig,
	TABLE_CONFIG,
	TRANSLATE_CONFIG,
	TranslateConfig
} from './app-configuration';
import { AppHttpInterceptor } from './app-http-interceptor';


@NgModule({
	imports: [
	],
	providers: [
		{
			provide: APP_CONFIG,
			useValue: AppConfig
		},
		{
			provide: TABLE_CONFIG,
			useValue: TableConfig
		},
		{
			provide: FORMS_CONFIG,
			useValue: FormsConfig
		},
		{
			provide: TRANSLATE_CONFIG,
			useValue: TranslateConfig
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppHttpInterceptor,
			multi: true
		}
	]
})
export class AppConfigModule { }
