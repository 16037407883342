import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { GrowlModule } from 'primeng/growl';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import {DataViewModule} from 'primeng/dataview';
import {PanelModule} from 'primeng/panel';
import {TabMenuModule} from 'primeng/tabmenu';
import { DropdownModule, DialogModule, ProgressBarModule, AutoCompleteModule, OverlayPanelModule, MessagesModule, MultiSelectModule, MenuModule } from 'primeng/primeng';

@NgModule({
	imports: [
		ButtonModule,
		GrowlModule,
		ToastModule,
		TableModule,
		ConfirmDialogModule,
		PasswordModule,
		RadioButtonModule,
		CheckboxModule,
		DataViewModule,
		PanelModule,
		TabMenuModule,
		DropdownModule,
		DialogModule,
		ProgressBarModule,
		AutoCompleteModule,
		OverlayPanelModule,
		MessagesModule,
		MultiSelectModule,
		MenuModule
	],
	exports: [
		ButtonModule,
		GrowlModule,
		ToastModule,
		TableModule,
		ConfirmDialogModule,
		PasswordModule,
		RadioButtonModule,
		CheckboxModule,
		DataViewModule,
		PanelModule,
		TabMenuModule,
		DropdownModule,
		DialogModule,
		ProgressBarModule,
		AutoCompleteModule,
		OverlayPanelModule,
		MessagesModule,
		MultiSelectModule,
		MenuModule
	]
})
export class PrimeNgModule { }
