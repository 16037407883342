import { DbModel } from '../base/DbModel';

export class ApplicationUser extends DbModel {
	id: string;
	name: string;
	surname: string;
	email: string;
	dateCreated: Date;
	dateModified: Date;
	dateLogin: Date;
	superadmin: '0' | '1';
	createdBy: string;
	modifiedBy: string;
	active: '0' | '1';

	constructor(obj?: any) {
		super();
		// if using super then
		// if you set default values in child properties
		// it gets overriden
		this.setFromDb(obj);
	}

	static create() {
		return new ApplicationUser({
			superadmin: '0',
			active: '1'
		});
	}

	// getters / setters
	get isActive() {
		return this.active && this.active === '1';
	}
	get isSuper() {
		return this.superadmin && this.superadmin === '1';
	}
}
