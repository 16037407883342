import { Component, OnInit } from '@angular/core';
import { ContactsService } from 'src/app/services/contacts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownItem } from 'src/app/classes/base/DropdownItem';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { OcrService } from 'src/app/services/ocr.service';
import { CacheService } from 'src/app/services/cache.service';
import { Contacts } from 'src/app/classes/crm/contacts';
import { ArcturConfirm } from 'src/app/decorators/ArcturConfirm';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-cached-content',
	templateUrl: './cached-content.component.html',
	styleUrls: ['./cached-content.component.scss']
})
export class CachedContentComponent implements OnInit {
	contact: any;
	cachedContacts: any;
	selectedImage: string;
	showList = true;
	processing = false;
	fieldFeed: DropdownItem[];
	categoryFeed: CheckItem[];
	imageText: any;
	isMobile = false;
	mmaUrl = 'crm/imageupload';
	contactDetail: Contacts;

	constructor(
		private contactsService: ContactsService,
		private ocrService: OcrService,
		private activatedRoute: ActivatedRoute,
		public cacheService: CacheService,
		public deviceService: DeviceDetectorService,
		private router: Router
		
	) { }

	ngOnInit() {
		this.isMobile = this.deviceService.isMobile();
		this.activatedRoute.params.subscribe(params => {
			if (params.id !== undefined) {
				this.showList = false;
				this.selectedImage = params.id;
				this.contactDetail = new Contacts();
				const imgObj = {'id' : params.id, 'path' : ''};
				
				this.contactDetail.contctImg.push(imgObj);
				const imageText = { 'data' : [
					{'value' : '', 'type' : 'name'},
					{'value' : '', 'type' : 'workPosition'},
					{'value' : '', 'type' : 'company'},
					{'value' : '', 'type' : 'address'},
					{'value' : '', 'type' : 'email'},
					{'value' : '', 'type' : 'phone'},
				], 'notes' : '', 'category' : [], 'id' : params.id};
				this.imageText = imageText;
			} else {
				//console.log(this.cacheService.notEditedImages);
				this.cachedContacts = this.cacheService.notEditedImages;
				/*
				this.contactsService.getCachedContacts()
					.then(
						(cahedContacts: any) => {
							this.cachedContacts = cahedContacts;
						}
					)
				*/
				this.showList = true;
				//this.contact = new Contacts();
			}
		});
		this.fieldFeed = [
			{ 'value': 'name', 'label': 'Ime in priimek' },
			{ 'value': 'workPosition', 'label': 'Delovno mesto' },
			{ 'value': 'company', 'label': 'Podjetje' },
			{ 'value': 'address', 'label': 'Naslov' },
			{ 'value': 'email', 'label': 'E-mail' },
			{ 'value': 'phone', 'label': 'GSM/telefon' }
		];
		this.contactsService.getCategory()
			.then(
				(category: CheckItem[]) => {
					this.categoryFeed = category;
				}
			);
	}

	removeInputElement(index: number) {
		this.imageText.data.splice(index, 1);
	}

	recognizeImage(){
		this.processing = true;
		this.ocrService.recognizePhoto(this.selectedImage)
			.then(
				(ocrText: any) => {
					this.imageText = ocrText;
					this.processing = false;
				}
			)

	}

	editContact(){
		this.router.navigate(['/crm/cachedcontent',this.selectedImage]);
	}

	@ArcturConfirm({
		header: 'Brisanje kontakta',
		message: 'Ste prepričani?',
	})
	delete(id: string) {


		this.contactsService.delete(id)
			.then(() => {
				const index: number = this.cacheService.notEditedImages.notEditedList.indexOf(id);
				this.cacheService.notEditedImages.notEditedList.splice(index);
				if(this.cacheService.notEditedImages.notEditedList && this.cacheService.notEditedImages.notEditedList.length === 0){
					this.router.navigate(['/crm/contacts', 'list']);
				}
			})
	}


	callSave(){
		this.ocrService.save(this.imageText)
		.then(
			(data: any) => {
				console.log(this.cacheService.notEditedImages.notEditedList);
				const index: number = this.cacheService.notEditedImages.notEditedList.indexOf(this.selectedImage);
				this.cacheService.notEditedImages.notEditedList.splice(index);
				this.router.navigate(['/crm/contacts', 'edit', data]);
			}
		)
	}

}
