// tslint:disable-next-line:max-line-length
import { Component, OnInit, ElementRef, NgZone, TemplateRef, Input, ViewChild, ContentChildren, QueryList, Output, EventEmitter, AfterViewInit, AfterContentInit, OnDestroy, Injector } from '@angular/core';
import { DomHandler, Message, PrimeTemplate, BlockableUI } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { FilesService } from 'src/app/services/files.service';
import { ArcturConfirm } from 'src/app/decorators/ArcturConfirm';
import { AppConfig, APP_CONFIG } from 'src/app/modules/config/app-configuration';
import { DropdownItem } from 'src/app/classes/base/DropdownItem';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	providers: [DomHandler]
})
export class FileUploadComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, BlockableUI {

	@Input() name: string;

	@Input() url: string;

	@Input() objectId: string;

	@Input() data = [];

	@Input() method = 'POST';

	@Input() multiple: boolean;

	@Input() accept: string;

	@Input() disabled: boolean;

	@Input() auto: boolean;

	@Input() withCredentials: boolean;

	@Input() maxFileSize: number;

	@Input() invalidFileSizeMessageSummary = '{0}: Invalid file size, ';

	@Input() invalidFileSizeMessageDetail = 'maximum upload size is {0}.';

	@Input() invalidFileTypeMessageSummary = '{0}: Invalid file type, ';

	@Input() invalidFileTypeMessageDetail = 'allowed file types: {0}.';

	@Input() style: string;

	@Input() styleClass: string;

	@Input() previewWidth = 50;

	@Input() chooseLabel = 'Choose';

	@Input() uploadLabel = 'Upload';

	@Input() cancelLabel = 'Cancel';

	@Input() showUploadButton = true;

	@Input() showCancelButton = true;

	@Input() mode = 'advanced';

	@Input() customUpload: boolean;

	@Output() beforeUpload: EventEmitter<any> = new EventEmitter();

	@Output() beforeSend: EventEmitter<any> = new EventEmitter();

	@Output() uploaded: EventEmitter<any> = new EventEmitter();

	@Output() error: EventEmitter<any> = new EventEmitter();

	@Output() cleared: EventEmitter<any> = new EventEmitter();

	@Output() removed: EventEmitter<any> = new EventEmitter();

	@Output() selected: EventEmitter<any> = new EventEmitter();

	@Output() progressed: EventEmitter<any> = new EventEmitter();

	@Output() uploadHandler: EventEmitter<any> = new EventEmitter();

	@ContentChildren(PrimeTemplate) templates: QueryList<any>;

	@ViewChild('advancedfileinput') advancedFileInput: ElementRef;

	@ViewChild('basicfileinput') basicFileInput: ElementRef;

	@ViewChild('content') content: ElementRef;

	@Input() files: File[];

	@Input()
	languages: DropdownItem[];

	languagesSelected = [];

	public progress = 0;

	public dragHighlight: boolean;

	public msgs: Message[];

	public fileTemplate: TemplateRef<any>;

	public contentTemplate: TemplateRef<any>;

	public toolbarTemplate: TemplateRef<any>;

	focus: boolean;

	imagePath: string

	isUploading = false;

	protected config: AppConfig;

	duplicateIEEvent: boolean;  // flag to recognize duplicate onchange event for file input
	constructor(
		private el: ElementRef,
		public domHandler: DomHandler,
		public sanitizer: DomSanitizer,
		public zone: NgZone,
		private filesService: FilesService,
		private authService: AuthService,
		inj: Injector
	) {
		this.config = inj.get(APP_CONFIG);
	}

	ngOnInit() {
		this.files = [];
		if(this.data === undefined){
			this.data = [];
		}
		
		if(this.data && this.data.length > 0 && this.data[0].id) {
			this.imagePath = '/mma_bin.php?id=' + this.data[0].id;
		} else {
			this.imagePath = './assets/images/contact-background-image.png';
		}
		
	}
	

	ngAfterContentInit() {
		this.templates.forEach((item) => {
			switch (item.getType()) {
				case 'file':
					this.fileTemplate = item.template;
					break;

				case 'content':
					this.contentTemplate = item.template;
					break;

				case 'toolbar':
					this.toolbarTemplate = item.template;
					break;

				default:
					this.fileTemplate = item.template;
					break;
			}
		});
	}

	ngAfterViewInit() {
		if (this.mode === 'advanced') {
			this.zone.runOutsideAngular(() => {
				this.content.nativeElement.addEventListener('dragover', this.onDragOver.bind(this));
			});
		}
	}

	onFileSelect(event) {
		if (event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent) {
			this.duplicateIEEvent = false;
			return;
		}

		this.msgs = [];
		if (!this.multiple) {
			this.files = [];
		}

		const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (!this.isFileSelected(file)) {
				if (this.validate(file)) {
					if (this.isImage(file)) {
						file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
					}

					this.files.push(files[i]);
				}
			}
		}

		this.selected.emit({ originalEvent: event, files: files });

		if (this.hasFiles() && this.auto) {
			this.upload();
		}

		if (event.type !== 'drop' && this.isIE11()) {
			this.clearIEInput();
		} else {
			this.clearInputElement();
		}
	}

	isFileSelected(file: File): boolean {
		for (const sFile of this.files) {
			if ((sFile.name + sFile.type + sFile.size) === (file.name + file.type + file.size)) {
				return true;
			}
		}

		return false;
	}

	isIE11() {
		return !!window['MSInputMethodContext'] && !!document['documentMode'];
	}

	validate(file: File): boolean {
		if (this.accept && !this.isFileTypeValid(file)) {
			this.msgs.push({
				severity: 'error',
				summary: this.invalidFileTypeMessageSummary.replace('{0}', file.name),
				detail: this.invalidFileTypeMessageDetail.replace('{0}', this.accept)
			});
			return false;
		}

		if (this.maxFileSize && file.size > this.maxFileSize) {
			this.msgs.push({
				severity: 'error',
				summary: this.invalidFileSizeMessageSummary.replace('{0}', file.name),
				detail: this.invalidFileSizeMessageDetail.replace('{0}', this.formatSize(this.maxFileSize))
			});
			return false;
		}

		return true;
	}

	private isFileTypeValid(file: File): boolean {
		const acceptableTypes = this.accept.split(',');
		for (const type of acceptableTypes) {
			const acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
				: file.type === type || this.getFileExtension(file) === type;

			if (acceptable) {
				return true;
			}
		}

		return false;
	}

	getTypeClass(fileType: string): string {
		return fileType.substring(0, fileType.indexOf('/'));
	}

	isWildcard(fileType: string): boolean {
		return fileType.indexOf('*') !== -1;
	}

	getFileExtension(file: File): string {
		return '.' + file.name.split('.').pop();
	}

	isImage(file: File): boolean {
		return /^image\//.test(file.type);
	}

	onImageLoad(img: any) {
		window.URL.revokeObjectURL(img.src);
	}

	upload() {
		this.isUploading = true;
		if (this.customUpload) {
			this.uploadHandler.emit({
				files: this.files
			});
		} else {
			this.msgs = [];
			const xhr = new XMLHttpRequest(),
				formData = new FormData();

				
			this.beforeUpload.emit({
				'xhr': xhr,
				'formData': formData
			});
			for (let i = 0; i < this.files.length; i++) {
				formData.append(this.name, this.files[i], this.files[i].name);
			}

			

			xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
				if (e.lengthComputable) {
					this.progress = Math.round((e.loaded * 100) / e.total);
				}

				this.progressed.emit({ originalEvent: e, progress: this.progress });
			}, false);

			
			xhr.onreadystatechange = () => {
				if (xhr.readyState === 4) {
					this.progress = 0;
					if (xhr.status >= 200 && xhr.status < 300) {
						this.uploaded.emit({ xhr: xhr, files: this.files });
						this.addUploadedFile(xhr);
					} else {
						this.error.emit({ xhr: xhr, files: this.files });
					}

					this.clear();
				}
			};
			let url = this.config.API_PATH + '/' + this.url;
			if(this.objectId !== undefined && this.objectId !== ''){
				url += '/' + this.objectId;
			}
			xhr.open(this.method, url, true);

			const jwt = this.authService.getJwtToken();
			xhr.setRequestHeader('Bearer-One', jwt);

			this.beforeSend.emit({
				'xhr': xhr,
				'formData': formData
			});

			xhr.withCredentials = this.withCredentials;

			

			xhr.send(formData);
			this.isUploading = false;
		}
		return false;
	}

	addUploadedFile(xhr: XMLHttpRequest) {
		if (xhr.response) {
			const json = JSON.parse(xhr.response).data;
			for (const doc of json) {
				this.data.push(doc);
			}
			this.imagePath = '/mma_bin.php?id=' + json[0].id + '&rand=' + Math.random();
		}
	}

	clear() {
		this.files = [];
		this.cleared.emit();
		this.clearInputElement();
	}

	@ArcturConfirm({
		translateHeader: 'txt_common_delete_confirmation_head',
		translateMessage: 'txt_common_confirm'
	})
	removeDocument(fileId: string) {
		const url = this.url + '/' + fileId;
		this.filesService.deleteFile(url)
			.then(() => {
				this.data.splice(this.data.findIndex(i => i.id === fileId), 1);
			}
			);

	}

	remove(index: number) {
		this.files.splice(index, 1);
	}

	clearInputElement() {
		if (this.advancedFileInput && this.advancedFileInput.nativeElement) {
			this.advancedFileInput.nativeElement.value = '';
		}

		if (this.basicFileInput && this.basicFileInput.nativeElement) {
			this.basicFileInput.nativeElement.value = '';
		}
	}

	clearIEInput() {
		if (this.advancedFileInput && this.advancedFileInput.nativeElement) {
			this.duplicateIEEvent = true; // IE11 fix to prevent onFileChange trigger again
			this.advancedFileInput.nativeElement.value = '';
		}
	}

	hasFiles(): boolean {
		return this.files && this.files.length > 0;
	}

	onDragEnter(e) {
		if (!this.disabled) {
			e.stopPropagation();
			e.preventDefault();
		}
	}

	onDragOver(e) {
		if (!this.disabled) {
			this.domHandler.addClass(this.content.nativeElement, 'ui-fileupload-highlight');
			this.dragHighlight = true;
			e.stopPropagation();
			e.preventDefault();
		}
	}

	onDragLeave(event) {
		if (!this.disabled) {
			this.domHandler.removeClass(this.content.nativeElement, 'ui-fileupload-highlight');
		}
	}

	onDrop(event) {
		if (!this.disabled) {
			this.domHandler.removeClass(this.content.nativeElement, 'ui-fileupload-highlight');
			event.stopPropagation();
			event.preventDefault();

			const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
			const allowDrop = this.multiple || (files && files.length === 1);

			if (allowDrop) {
				this.onFileSelect(event);
			}
		}
	}

	onFocus() {
		this.focus = true;
	}

	onBlur() {
		this.focus = false;
	}

	formatSize(bytes) {
		if (bytes === 0) {
			return '0 B';
		}
		const k = 1000,
			dm = 3,
			sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	onSimpleUploaderClick(event: Event) {
		if (this.hasFiles()) {
			this.upload();
		}
	}

	getBlockableElement(): HTMLElement {
		return this.el.nativeElement.children[0];
	}

	ngOnDestroy() {
		if (this.content && this.content.nativeElement) {
			this.content.nativeElement.removeEventListener('dragover', this.onDragOver);
		}
	}

	invalidUpload() {
		return this.files && this.files.length === 0;
	}

	setFileLng(obj: any){
		const fileLng = {'id': obj.id, 'lng': obj.global_languages_code, 'name': this.name};
		this.filesService.updateDocLanguage(fileLng).then();
	}

}
