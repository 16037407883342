import { NgModule } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SharedModule } from '../shared/shared.module';
import { TestRoutingComponent } from 'src/app/components/test-routing/test-routing.component';
import { LoginComponent } from 'src/app/components/auth/login/login.component';
import { ForgotPasswordComponent } from 'src/app/components/auth/forgot-password/forgot-password.component';
import { CreateAccountComponent } from 'src/app/components/auth/create-account/create-account.component';
import { BaseAuthComponent } from 'src/app/components/auth/base-auth/base-auth.component';
import { BaseAdminComponent } from 'src/app/components/admin/base-admin/base-admin.component';
import { AdminUsersComponent } from 'src/app/components/admin/admin-users/admin-users.component';
import { ChangePasswordComponent } from 'src/app/components/auth/change-password/change-password.component';
import { PathNotFoundComponent } from 'src/app/components/base/path-not-found/path-not-found.component';
import { AdminAboutComponent } from 'src/app/components/admin/admin-about/admin-about.component';
import { AdminUserComponent } from 'src/app/components/admin/admin-user/admin-user.component';
import { MultiLanguageComponent } from 'src/app/components/form-elements/multi-language/multi-language.component';
import { InputErrorComponent } from 'src/app/components/form-elements/input-error/input-error.component';
import { AnimatedRouterOutletComponent } from 'src/app/components/generic/animated-router-outlet/animated-router-outlet.component';
import { EntryPageComponent } from 'src/app/components/base/entry-page/entry-page.component';
import { MenuComponent } from 'src/app/components/base/menu/menu.component';
import { ContactsComponent } from 'src/app/components/crm/contacts/contacts.component';
import { ContactsDetailComponent } from 'src/app/components/crm/contacts-detail/contacts-detail.component';
import { InputTextComponent } from 'src/app/components/form-elements/input-text/input-text.component';
import { InputCheckboxComponent } from 'src/app/components/form-elements/input-checkbox/input-checkbox.component';
import { OcrComponent } from 'src/app/components/crm/ocr/ocr.component';
import {WebcamModule} from 'ngx-webcam';
import { NgProgressModule } from '@ngx-progressbar/core';
import { InputSelectComponent } from 'src/app/components/form-elements/input-select/input-select.component';
import { CategoryComponent } from 'src/app/components/crm/category/category.component';
import { FileUploadComponent } from 'src/app/components/form-elements/file-upload/file-upload.component';
import { FilterSearchComponent } from 'src/app/components/crm/filter-search/filter-search.component';
import { CachedContentComponent } from 'src/app/components/crm/cached-content/cached-content.component';
import { StoredUploaderComponent } from 'src/app/components/crm/stored-uploader/stored-uploader.component';
import { ArcWebCamComponent } from 'src/app/components/arc-web-cam/arc-web-cam.component';

@NgModule({
	imports: [
		SharedModule,
		WebcamModule,
		NgProgressModule
	],
	declarations: [
		AppComponent,
		TestRoutingComponent,
		LoginComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		CreateAccountComponent,
		BaseAuthComponent,
		BaseAdminComponent,
		AdminUsersComponent,
		AdminUserComponent,
		PathNotFoundComponent,
		AdminAboutComponent,
		MultiLanguageComponent,
		InputErrorComponent,
		AnimatedRouterOutletComponent,
		EntryPageComponent,
		MenuComponent,
		ContactsComponent,
		ContactsDetailComponent,
		InputTextComponent,
		InputCheckboxComponent,
		OcrComponent,
		InputSelectComponent,
		CategoryComponent,
		FileUploadComponent,
		FilterSearchComponent,
		CachedContentComponent,
		StoredUploaderComponent,
		ArcWebCamComponent
	],
	exports: [
		AppComponent,
		TestRoutingComponent,
		LoginComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		CreateAccountComponent,
		BaseAuthComponent,
		BaseAdminComponent,
		AdminUsersComponent,
		AdminUserComponent,
		PathNotFoundComponent,
		AdminAboutComponent,
		MultiLanguageComponent,
		InputErrorComponent,
		AnimatedRouterOutletComponent,
		EntryPageComponent,
		MenuComponent,
		ContactsComponent,
		ContactsDetailComponent,
		InputTextComponent,
		InputCheckboxComponent,
		OcrComponent,
		WebcamModule,
		InputSelectComponent,
		CategoryComponent,
		FileUploadComponent,
		FilterSearchComponent,
		CachedContentComponent,
		StoredUploaderComponent,
		ArcWebCamComponent
	]
})
export class ComponentsModule { }
