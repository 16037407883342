import { Injector, ViewChildren, QueryList } from '@angular/core';
import {
	AppConfig,
	APP_CONFIG,
	FormsConfig,
	FORMS_CONFIG
} from 'src/app/modules/config/app-configuration';
import { ConfirmationService } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BaseAppService } from 'src/app/services/base-app.service';
import { UserService } from '../../services/user.service';

export abstract class ConfigBasedComponent {

	public config: AppConfig;
	public formsConfig: FormsConfig;
	protected baseAppService: BaseAppService;
	protected confirmationService: ConfirmationService;
	public userService: UserService;
	route: ActivatedRoute;

	get canLeave() {
		if (!this.forms) {
			return true;
		}
		if (!this.forms.length) {
			return true;
		}
		const dirty = this.forms.find(f => !f.pristine);
		return dirty ? false : true;
	}

	@ViewChildren(NgForm)
	forms: QueryList<NgForm>;

	constructor(inj: Injector) {
		this.config = inj.get(APP_CONFIG);
		this.formsConfig = inj.get(FORMS_CONFIG);
		this.baseAppService = inj.get(BaseAppService);
		this.confirmationService = inj.get(ConfirmationService);
		this.userService = inj.get(UserService);
		this.route = inj.get(ActivatedRoute);
	}
}
