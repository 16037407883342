import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { UserService } from 'src/app/services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CacheService } from 'src/app/services/cache.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { ConnectionService } from 'ng-connection-service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	
})
export class MenuComponent implements OnInit {
	showMenu = false;
	menu: MenuItem[];
	menuMobile: MenuItem[];
	activeItem: boolean;
	isOnline = true;

	constructor(
		public users: UserService,
		private deviceService: DeviceDetectorService,
		private cacheService: CacheService,
		private contactsService: ContactsService,
		private connectionService: ConnectionService
		
	) { }

	ngOnInit() {
		
		this.menu = [
			{label: 'Iskanje', routerLink: '/crm/contacts/list', id: 'search'},
			{label: 'Dodaj novi kontakt', routerLink: '/crm/contacts/edit', id: 'new-contact'},
			{label: 'ocr', routerLink: '/crm/ocr', id: 'temp-ocr'},
		]
		this.contactsService.getCachedContacts()
			.then(
				(cachedContacts: any) => {
					this.cacheService.notEditedImages.notEditedList = cachedContacts;
				}
			)
		this.connectionService.monitor().subscribe(isConnected => {
			this.isOnline = isConnected;
		})
	}

	hasStoredImages(){
		return  this.cacheService.notEditedImages.notEditedList && this.cacheService.notEditedImages.notEditedList.length;
	}

	showInMenu(id: string){
		if(id === 'temp-ocr'){
			if(this.deviceService.isMobile()){
				return true;
			}
			return false;
		}
		return true
	}

}
