import { Component, OnInit } from '@angular/core';
import { ApplicationAnimations } from 'src/app/classes/application-animations';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-animated-router-outlet',
	templateUrl: './animated-router-outlet.component.html',
	styleUrls: ['./animated-router-outlet.component.scss'],
	animations: [
		ApplicationAnimations.fadeInOut()
	]
})
export class AnimatedRouterOutletComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

	routeAnimations(outlet: RouterOutlet) {
		return outlet.activatedRouteData['animation'] || null;
	}
}
