import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
	name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

	private storageUnits = ['B', 'KB', 'MB', 'GB', 'TB'];
	decimal: DecimalPipe;

	constructor() {
		this.decimal = new DecimalPipe('sl');
	}

	transform(value: any, args?: any): any {
		let i = 0;
		while (value / 1024 > 1) {
			value = value / 1024;
			i++;
		}

		// if not bytes then round it up
		if (i !== 0) {
			value = value.toFixed(2);
			value = this.decimal.transform(value, '1.1-2', 'sl');
		}

		return `${value} ${this.storageUnits[i]}`;
	}

}
