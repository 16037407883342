import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import Tesseract from 'tesseract.js'
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { NgProgress } from '@ngx-progressbar/core';
import { OcrService } from 'src/app/services/ocr.service';
import { DropdownItem } from 'src/app/classes/base/DropdownItem';
import { ContactsService } from 'src/app/services/contacts.service';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchCheckerService } from 'src/app/services/search-checker.service';
import { ConnectionService } from 'ng-connection-service';
import { CacheService } from 'src/app/services/cache.service';
import { MessageService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { ArcWebCam } from 'src/app/classes/ArcWebCam';
import { ArcWebCamComponent } from '../../arc-web-cam/arc-web-cam.component';

@Component({
	selector: 'app-ocr',
	templateUrl: './ocr.component.html',
	styleUrls: ['./ocr.component.scss'],

})
export class OcrComponent implements OnInit, OnDestroy {
	// toggle webcam on/off
	public showWebcam = true;
	public allowCameraSwitch = false;
	public deviceId: string;
	public camWidth = 720;
	public camHeight = 320;
	public videoOptions: MediaTrackConstraints = {
		//width: {ideal: this.camWidth},
		//height: {ideal: this.camHeight}
	};
	public errors: WebcamInitError[] = [];

	// latest snapshot
	public webcamImage: WebcamImage = null;
	imageText: any;
	processing = false;
	imageQuality = 1;
	isOnline = navigator.onLine;
	selectedCamera = '';
	cameraIndex = 0;

	get allCamers() {
		if (!this.web) {
			return [];
		}
		return this.web.availableVideoInputs;
	}
	get multipleWebcamsAvailable() {
		return this.allCamers && this.allCamers.length > 1;
	}

	// webcam snapshot trigger
	private trigger: Subject<void> = new Subject<void>();
	// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
	private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

	fieldFeed: DropdownItem[];
	categoryFeed: CheckItem[];

	@ViewChild('webcamComponent')
	web: ArcWebCamComponent;

	constructor(
		public ngProgress: NgProgress,
		private ocrService: OcrService,
		private contactsService: ContactsService,
		private deviceService: DeviceDetectorService,
		private connectionService: ConnectionService,
		public searhEnableService: SearchCheckerService,
		private cacheService: CacheService,
		private router: Router,
		private message: MessageService
	) { 
		searhEnableService.showSearchComponent = false;
	}

	ngOnInit() {
		this.connectionService.monitor().subscribe(isConnected => {
			this.isOnline = isConnected;
		})
		const isMobile = this.deviceService.isMobile();
		const menuHeight = document.getElementById('menu-container').clientHeight;
		if (isMobile) {
			//this.camWidth = window.innerWidth;
			this.camWidth = window.innerWidth;
			this.camHeight = (window.innerHeight-140-menuHeight);
		}
		//console.log(window.innerWidth);
		//console.log(isMobile);
		

		this.fieldFeed = [
			{ 'value': 'name', 'label': 'ime in priimek' },
			{ 'value': 'workPosition', 'label': 'delovno mesto' },
			{ 'value': 'company', 'label': 'podjetje' },
			{ 'value': 'address', 'label': 'naslov' },
			{ 'value': 'email', 'label': 'e-mail' },
			{ 'value': 'gsm', 'label': 'GSM' },
			{ 'value': 'tel', 'label': 'telefon' },
			{ 'value': 'web', 'label': 'spletna stran' },
			{ 'value': 'notes', 'label': 'opombe' }
		];
		this.contactsService.getCategory()
			.then(
				(category: CheckItem[]) => {
					this.categoryFeed = category;
				}
			);

		// this.setCameras();

	}

	// setCameras(){
	// 	ArcWebCam.getAvailableVideoInputs()
	// 	.then((mediaDevices: MediaDeviceInfo[]) => {
			
	// 		if(mediaDevices){
	// 			this.allCamers = [];
	// 			this.ocrService.logCameras(mediaDevices);
	// 			mediaDevices.forEach(el => {
	// 				if(el.kind === 'videoinput' && el.label.toLowerCase().indexOf('back') !== -1){
	// 					this.allCamers.push(el.deviceId);
	// 					if(this.selectedCamera === ''){
	// 						this.selectedCamera = el.deviceId;
	// 						this.deviceId = el.deviceId;
	// 						this.nextWebcam.next(el.deviceId);
	// 						//this.nextWebcam.next(true);
	// 					}
	// 				}
	// 			})
	// 			this.multipleWebcamsAvailable = mediaDevices.length > 1;
				
	// 		}
	// 	});
	// }

	ngOnDestroy(){
		this.searhEnableService.showSearchComponent = true;
	}

	public triggerSnapshot(): void {
		this.trigger.next();
		this.imageText = null;
		this.showWebcam = false;
		//this.deviceId = this.allCamers[this.cameraIndex];
		//this.nextWebcam.next(this.allCamers[this.cameraIndex]);
	}

	cacheImage() {
		this.cacheService.setScanedImages(this.webcamImage);
		this.message.add({
			severity: 'success',
			summary: 'Slika začasno shranjena'
		});
		this.imageText = null;
		this.webcamImage = null;
		this.showWebcam = true;
		
	}

	showCamera() {
		console.log('camera index: ' + this.cameraIndex);
		console.log(this.allCamers);
		//this.deviceId = this.allCamers[this.cameraIndex];
		//this.nextWebcam.next(this.allCamers[this.cameraIndex]);
		//this.showNextWebcam(this.allCamers[this.cameraIndex]);
		this.imageText = null;
		//this.nextWebcam.next(true);
		this.showWebcam = true;
		//this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
		//this.router.navigate(["/crm/ocr"])); 
		//this.setCameras();
	}

	recognizeImage() {
		this.processing = true;
		this.ocrService.sendPhoto(this.webcamImage)
			.then(
				(ocrText: any) => {
					this.imageText = ocrText;
					this.processing = false;
				}
			)
		//this.imageText = this.webcamImage.imageAsDataUrl;
		/*
		Tesseract.recognize(this.webcamImage.imageAsDataUrl, {
				lang: 'slv',
				langPath: './assets/tessdata/'

			})
			.progress(message => console.log('progress is: ', message) )
			.catch(err => {
				console.log('napaka');
				console.error(err);
			})
			.then(result => {
				console.log(result);
				this.imageText = result.text;
			})
			.finally(resultOrError => {
				console.log('konec');
				//this.progress.complete();
			});
			console.log('exit');
			*/
	}

	public toggleWebcam(): void {
		this.showWebcam = !this.showWebcam;
	}

	public handleInitError(error: WebcamInitError): void {
		this.errors.push(error);
	}

	public showNextWebcam(directionOrDeviceId: boolean | string): void {
		// true => move forward through devices
		// false => move backwards through devices
		// string => move to device with given deviceId

		this.cameraIndex ++;
		const back = this.allCamers.filter(i => i.label.toLowerCase().indexOf('back') !== -1);

		if(this.cameraIndex >= back.length) {
			this.cameraIndex = 0;
		}

		this.deviceId = back[this.cameraIndex].deviceId;
		this.nextWebcam.next(back[this.cameraIndex].deviceId);
	}

	public handleImage(webcamImage: WebcamImage): void {
		this.webcamImage = webcamImage;
	}

	public cameraWasSwitched(deviceId: string): void {
		console.log('active device: ' + deviceId);
		this.deviceId = deviceId;
	}

	public get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	public get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}

	removeInputElement(index: number) {
		this.imageText.data.splice(index, 1);
	}
 
	getValues(type: string, index: number) {

	}

	callSave() {
		console.log('call save');
		this.ocrService.save(this.imageText)
			.then(
				(data: any) => {
					this.imageText = null;
					this.webcamImage = null;
					this.showWebcam = true;

				}
			).catch(err => console.log(err));
	}

}
