import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { ConfigBasedComponent } from 'src/app/classes/base/ConfigBasedComponent';
import { AdminService } from 'src/app/services/admin.service';
import { ApplicationUser } from 'src/app/classes/admin/ApplicationUser';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ArcturConfirm } from 'src/app/decorators/ArcturConfirm';
import { ChangePassword } from 'src/app/interface/Auth';

@Component({
	selector: 'app-admin-user',
	templateUrl: './admin-user.component.html',
	styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent extends ConfigBasedComponent {

	user: ApplicationUser;
	changePassword: ChangePassword;

	// childrens
	@ViewChild('formUserPassword')
	formUserPwd: NgForm;

	constructor(
		private admin: AdminService,
		private router: Router,
		inj: Injector
	) {
		super(inj);
		this.route.params.subscribe(params => {
			this.admin.getUser(params['id'])
					.then(u => this.user = u);
		});
		this.clearPassword();
	}


	private clearPassword() {
		this.changePassword = {
			new: '',
			repeat: ''
		};
		if (this.formUserPwd) {
			this.formUserPwd.reset();
		}
	}


	// user
	@ArcturConfirm({
		header: 'common_confirm',
		message: 'common_confirm_save_user'
	})
	save() {
		this.admin.saveUser(this.user)
			.then((id: string) => this.user.id = id);
	}

	// password
	@ArcturConfirm({
		header: 'common_confirm',
		message: 'common_confirm_change_password'
	})
	savePassword() {
		this.admin.savePassword(this.user, this.changePassword)
			.then(() => this.clearPassword());
	}
}
