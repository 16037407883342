import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { ButtonModule } from 'primeng/button';
import { AllowCookiesComponent } from 'src/app/components/base/allow-cookies/allow-cookies.component';
import { AllowCookiesModuleConfig, ALLOW_COOKIES_CONFIG } from '../config/app-configuration';
import { AllowCookiesService } from 'src/app/services/allow-cookies.service';

const defaultOptions: AllowCookiesModuleConfig = {
	COOKIENAME: 'app-cookies',
	ACCEPTED: '1',
	DECLINED: '0'
};

/** Its allowed cookies, becase ngxcookies usees same names and
 * we want to avoid confusion
 */


@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		ButtonModule
	],
	declarations: [
		AllowCookiesComponent
	],
	exports: [
		AllowCookiesComponent
	]
})
export class AllowCookiesModule {
	static forRoot(options: AllowCookiesModuleConfig = defaultOptions): ModuleWithProviders {
		return {
			ngModule: AllowCookiesModule,
			providers: [
				AllowCookiesService,
				{
					provide: ALLOW_COOKIES_CONFIG,
					useValue: options
				}
			]
		};
	}
}