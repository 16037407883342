import { Injectable, Injector } from '@angular/core';
import { User } from '../classes/auth/User';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	current: User;

	// returns if current use is super
	get isSuper() {
		if (!this.current) {
			return false;
		}
		return this.current.isSuper;
	}

	constructor() {}

	// sets user from jwt string
	setUser(jwt: string) {
		if (jwt) {
			this.current = new User(jwt);
		}
	}

}
