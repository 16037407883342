import { JWT } from 'src/app/classes/JWT';
import { ArcturHelper } from 'src/app/classes/helpers/ArcturHelper';

export class User {
	id: number;
	name: string;
	surname: string;
	email: string;
	superadmin: '0' | '1';
	get isSuper() {
		return (this.superadmin && this.superadmin === '1') ? true : false;
	}
	constructor(jwt: string) {

		const jwtObj = new JWT(jwt);
		const user = jwtObj.payload.data;
		for (const key of Object.keys(user)) {
			this[key] = user[key];
		}
	}
}
