import { Component } from '@angular/core';
import { Message } from 'primeng/primeng';
import { AuthService } from './services/auth.service';
import { ArcturConfirm } from './decorators/ArcturConfirm';
import { UserService } from './services/user.service';
import { SearchCheckerService } from './services/search-checker.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConnectionService } from 'ng-connection-service';
import { CacheService } from './services/cache.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'Arctur';
	msgs: Message[] = [];
	isOnline = true;

	constructor(
		private auth: AuthService,
		private user: UserService,
		public searchEnableService: SearchCheckerService,
		public deviceService: DeviceDetectorService,
		private connectionService: ConnectionService,
		private cacheService: CacheService,
		public searhEnableService: SearchCheckerService
		
	){
	}

	@ArcturConfirm({
		header: 'Odjava',
		message: 'Ste prepričani?',
	})
	logout() {
		this.auth.logout();
	}

	getUserData(){
		return this.user.current.name + ' ' + this.user.current.surname;
	}

	isLogin(){
		if(this.user.current){
			return true;
		}
		return false;
	}

	canUpload(){
		this.connectionService.monitor().subscribe(isConnected => {
			this.isOnline = isConnected;
		})
		//this.cacheService.scanedImages();
		
		const cachedImages = this.cacheService.scanedImages;
		//console.log(cachedImages.length);
		if(cachedImages.length && this.isOnline && this.isLogin){
			return true;
		}
		return false;
	}

}
