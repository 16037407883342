import { DbModel } from "../base/DbModel";
import { File } from 'src/app/interface/file';

export class Contacts extends DbModel {
	id: string;
	contact: string;
	work_position: string;
	contact_name: string;
	contact_surname: string;
	email: string;
	gsm: string;
	tel: string;
	web: string;
	company: string;
	address: string;
	notes: string;
	category?: string[];
	contctImg: File[];

	constructor(obj?: any) {
		super();
		// if using super then
		// if you set default values in child properties
		// it gets overriden
		this.setFromDb(obj);
		this.contctImg = this.contctImg
						? this.contctImg.map(p => new File(p)) : [];
	}


}