import { DbModel } from './DbModel';
import { Injector } from '@angular/core';
import { ConfigBasedHttpService } from './ConfigBasedService';
import { map } from 'rxjs/operators';
import { ArcturToDbValues } from 'src/app/decorators/ArcturToDbValues';

export abstract class DbService<T extends DbModel> extends ConfigBasedHttpService {

	// pot do apija -> naprej od config -> npr: admin/users : POMEMBNO: ni začetnega slasha
	abstract apiPath: string;

	// nastavimo constructor type torej naprimer type = ApplicationUser;
	abstract type: (new (obj?: object) => T);
	constructor(
		inj: Injector
	) {
		super(inj);
	}

	// pridobi vse
	all() {
		return this.getAll();
	}

	// pridobi enege
	// v primeru da je null vrne nov objekt istega tipa
	get(id?: string): Promise<T> {
		if (id === undefined || id === null) {
			return new Promise(res => res(new this.type()));
		}
		return this.getOne(id);
	}

	// shrani
	// ce je id potem PUT
	// drugace POST
	@ArcturToDbValues()
	save(obj: T) {
		if (obj.id) {
			return this.doPut(obj);
		}
		return this.doPost(obj);
	}

	// delete
	delete(id: string) {
		return this.http.delete(`${this.config.API_PATH}/${this.apiPath}/${id}`)
			.toPromise();
	}


	private getAll(): Promise<T[]> {
		return this.http.get<T[]>(`${this.config.API_PATH}/${this.apiPath}`)
			.pipe(
				map(items => items ? items.map(i => new this.type(i)) : [])
			).toPromise();
	}

	private getOne(id: string): Promise<T> {
		return this.http.get<T>(`${this.config.API_PATH}/${this.apiPath}/${id}`)
			.pipe(
				map(item => new this.type(item))
			).toPromise();
	}

	private doPost(obj: T) {
		return this.http.post<T>(`${this.config.API_PATH}/${this.apiPath}`, obj)
			.toPromise();
	}
	private doPut(obj: T) {
		return this.http.put<T>(`${this.config.API_PATH}/${this.apiPath}`, obj)
			.toPromise();
	}
}
