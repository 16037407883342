import { Injectable, Injector } from '@angular/core';
import { DbService } from '../classes/base/DbService';
import { Ocr } from '../classes/crm/ocr';
import { WebcamImage } from 'ngx-webcam';

@Injectable({
	providedIn: 'root'
})
export class OcrService extends DbService<Ocr> {
	apiPath = 'crm/ocr';
	type = Ocr;

	constructor(
		inj: Injector
	) {
		super(inj);
	}

	sendPhoto(binData: WebcamImage) {
		return this.http.post<any>(`${this.config.API_PATH}/${this.apiPath}-scan/`, binData)
			.toPromise();
	}

	recognizePhoto(id: string) {
		return this.http.get<any>(`${this.config.API_PATH}/${this.apiPath}-scan-photo/` + id)
			.toPromise();
	}

	logCameras(cameraDevices: any){
		return this.http.post<any>(`${this.config.API_PATH}/crm/log-camera/`, cameraDevices)
			.toPromise();
	}
}
