import { Component, OnInit, Injector } from '@angular/core';
import { ConfigBasedComponent } from 'src/app/classes/base/ConfigBasedComponent';
import { AdminService } from 'src/app/services/admin.service';
import { ApplicationUser } from 'src/app/classes/admin/ApplicationUser';
import { ArcturConfirm } from 'src/app/decorators/ArcturConfirm';

@Component({
	selector: 'app-admin-users',
	templateUrl: './admin-users.component.html',
	styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent extends ConfigBasedComponent{

	users: ApplicationUser[];

	constructor(
		private admin: AdminService,
		inj: Injector
	) {
		super(inj);
		this.admin.getUsers()
			.then(u => this.users = u);
	}

	@ArcturConfirm({
		header: 'common_activate',
		message: 'common_activate_msg'
	})
	activate(user: ApplicationUser) {
		this.admin.activateUser(user).then(() => user.active = '1');
	}

	@ArcturConfirm({
		header: 'common_deactivate',
		message: 'common_deactivate_msg'
	})
	deactivate(user: ApplicationUser) {
		this.admin.deactivateUser(user).then(() => user.active = '0');
	}

	@ArcturConfirm({
		header: 'common_change',
		message: 'common_promote_to_super_msg'
	})
	promote(user: ApplicationUser) {
		this.admin.promoteToSuper(user).then(() => user.superadmin = '1');
	}

	@ArcturConfirm({
		header: 'common_change',
		message: 'common_demote_from_super_msg'
	})
	demote(user: ApplicationUser) {
		this.admin.demoteFromSuper(user).then(() => user.superadmin = '0');
	}

}
