import { Component, OnInit, Injector } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FormsConfig, FORMS_CONFIG } from 'src/app/modules/config/app-configuration';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

	email: string;
	emailSent = false;

	formsConfig: FormsConfig;

	constructor(
		private authService: AuthService,
		inj: Injector
	) {
		this.formsConfig = inj.get(FORMS_CONFIG);
	}

	ngOnInit() {
	}

	forgotPassword() {
		this.authService.forgotPassword(this.email)
			.then(() => {
				this.emailSent = true;
			});
	}

}
