import { Component, OnInit } from '@angular/core';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
	categoryDetail: CheckItem;
	categorys: CheckItem[];
	insert = true;

	constructor(
		private contactsService: ContactsService
	) {
		this.categoryDetail = new CheckItem();
	}

	ngOnInit() {
		this.contactsService.getCategory()
			.then(
				(category: CheckItem[]) => {
					this.categorys = category;
				}
			);
	}

	callSave() {
		if (this.categoryDetail.key) {
			this.insert = false;
		}
		this.contactsService.insertUpdateCategorys(this.categoryDetail)
			.then((category) => {
				this.categoryDetail = new CheckItem();
				if (this.insert) {
					this.categorys.push(category);
					this.categorys.sort((x, y) => {
						return x.value.localeCompare(y.value);
					})
				}

			})
	}

	delete (id: string) {
		this.contactsService.deleteCategory(id)
			.then(() => {
				this.categorys = this.categorys.filter(el => el.key !== id);
			})
	}

	selectCategory(id: string) {
		this.categoryDetail = this.categorys.find(el => el.key === id);
	}

}
