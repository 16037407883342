import { Pipe, PipeTransform, Inject } from '@angular/core';
import { FORMS_CONFIG, FormsConfig } from '../modules/config/app-configuration';

@Pipe({
	name: 'inputErrorKey'
})
export class InputErrorKeyPipe implements PipeTransform {

	constructor(@Inject(FORMS_CONFIG) private formConfig: FormsConfig) { }
	// if to use 4pm translate module
	transform(errorKey: string, errorObj?: object): any {
		const errKey = `form_error_${errorKey}`;

		return this.formConfig.TRANSLATE_ERRORS ?
				this.translateError(errKey, errorObj) :
					this.baseError(errKey, errorObj);
	}

	// from translation
	translateError(errorKey: string, errorObj?: object) {
		throw new Error('Translate module not implemented');
	}

	// gets for app configuration
	baseError(errorKey: string, errorObj?: object) {
		if (this.formConfig.BASE_VALIDATION_ERRORS[errorKey]) {
			// if there are values to replace (like minlength number etc)
			if (errorObj && this.formConfig.ERRORS_REPLACE_VALUES[errorKey]) {
				return this.replaceValues({
					errorMsg: this.formConfig.BASE_VALIDATION_ERRORS[errorKey],
					replaceValues: this.formConfig.ERRORS_REPLACE_VALUES[errorKey],
					errorObj: errorObj
				});
			}
			return this.formConfig.BASE_VALIDATION_ERRORS[errorKey];
		}
		throw new Error(`errorKey ${errorKey} doesnt exist`);
	}

	// replaces values in string with actual values that input
	// error object has (ie. minlength has requiredValue)
	replaceValues({errorMsg, replaceValues, errorObj}): string {
		let message = errorMsg;
		for (const key of replaceValues) {
			message = message.replace(key, errorObj[key]);
		}
		return message;
	}


}
