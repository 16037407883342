import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { LoginComponent } from 'src/app/components/auth/login/login.component';
import { ForgotPasswordComponent } from 'src/app/components/auth/forgot-password/forgot-password.component';
import { CreateAccountComponent } from 'src/app/components/auth/create-account/create-account.component';
import { CreateAccTokenResolve } from 'src/app/resolvers/create-acc-token-resolver.service';
import { BaseAuthComponent } from 'src/app/components/auth/base-auth/base-auth.component';
import { BaseAdminComponent } from 'src/app/components/admin/base-admin/base-admin.component';
import { AdminUsersComponent } from 'src/app/components/admin/admin-users/admin-users.component';
import { ChangePasswordComponent } from 'src/app/components/auth/change-password/change-password.component';
import { JwtResolve } from 'src/app/resolvers/jwt-resolve.service';
import { PathNotFoundComponent } from 'src/app/components/base/path-not-found/path-not-found.component';
import { AdminAboutComponent } from 'src/app/components/admin/admin-about/admin-about.component';
import { AdminUserComponent } from 'src/app/components/admin/admin-user/admin-user.component';
import { AuthType } from 'src/app/enums/Auth';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { ContactsComponent } from 'src/app/components/crm/contacts/contacts.component';
import { ContactsDetailComponent } from 'src/app/components/crm/contacts-detail/contacts-detail.component';
import { OcrComponent } from 'src/app/components/crm/ocr/ocr.component';
import { CategoryComponent } from 'src/app/components/crm/category/category.component';
import { CanDeactivateService } from 'src/app/services/can-deactivate.service';
import { CachedContentComponent } from 'src/app/components/crm/cached-content/cached-content.component';


const appRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		data: {
			auth: !AuthType.NotLoggedIn,
			animation: 'animate-auth'
		},
		component: BaseAuthComponent,
	},
	{
		path: 'crm',
		canActivate: [AuthGuard],
		data: {
			auth: AuthType.User,
			animation: 'animate-auth'
		},
		children: [
			{
				path: '',
				redirectTo: 'contacts',
				pathMatch: 'full'
			},
			{
				path: 'contacts',
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'list' },
					{ path: 'list', component: ContactsComponent },
					{ path: 'edit', component: ContactsDetailComponent, canDeactivate: [CanDeactivateService] },
					{ path: 'edit/:id', component: ContactsDetailComponent, canDeactivate: [CanDeactivateService] },
				]
			},
			{
				path: 'ocr',
				component: OcrComponent

			},
			{
				path: 'cachedcontent',
				component: CachedContentComponent

			},
			{
				path: 'cachedcontent/:id',
				component: CachedContentComponent

			},
			{
				path: 'category',
				component: CategoryComponent
			}
		]
	},
	{
		path: 'auth',
		component: BaseAuthComponent,
		data: {
			auth: AuthType.NotLoggedIn,
			animation: 'animate-auth'
		},
		canActivate: [AuthGuard],
		children: [
			{
				path: 'login',
				component: LoginComponent,
				data: {
					animation: 'animate-login'
				}
			}, {
				path: 'forgot-password',
				component: ForgotPasswordComponent,
				data: {
					animation: 'animate-forgot-password'
				}
			}, {
				path: 'change-password/:jwt',
				component: ChangePasswordComponent,
				resolve: {
					validJwt: JwtResolve
				},
				data: {
					animation: 'animate-change-password'
				}
			}, {
				path: 'create-account/:token',
				component: CreateAccountComponent,
				resolve: {
					email: CreateAccTokenResolve
				},
				data: {
					animation: 'animate-change-password'
				}
			}
		]
	},
	{
		path: 'admin',
		component: BaseAdminComponent,
		canActivate: [AuthGuard],
		data: {
			auth: AuthType.SuperAdmin,
			animation: 'animate-admin'
		},
		children: [
			{
				path: 'user',
				component: AdminUserComponent
			}, {
				path: 'user/:id',
				component: AdminUserComponent
			}, {
				path: 'users',
				component: AdminUsersComponent
			}, {
				path: 'about',
				component: AdminAboutComponent
			}
		]
	}, {
		path: 'member',
		component: BaseAdminComponent,
		canActivate: [AuthGuard],
		data: {
			auth: AuthType.User,
			animation: 'animate-member'
		}
	}, {
		path: '**',
		component: PathNotFoundComponent
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, { enableTracing: false })
	],
	exports: [
		RouterModule
	]
})
export class AppRouterModule { }
