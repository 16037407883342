import { Injector } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'src/app/modules/config/app-configuration';
import { HttpClient } from '@angular/common/http';

export abstract class ConfigBasedService {

	protected config: AppConfig;
	constructor(inj: Injector) {
		this.config = inj.get(APP_CONFIG);
	}
}
export abstract class ConfigBasedHttpService extends ConfigBasedService {
	protected http: HttpClient;
	constructor(inj: Injector) {
		super(inj);
		this.http = inj.get(HttpClient);
	}
}

