import { Component, forwardRef, Input, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';

@Component({
	selector: 'app-multi-language',
	templateUrl: './multi-language.component.html',
	styleUrls: ['./multi-language.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultiLanguageComponent),
			multi: true
		}, {
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => MultiLanguageComponent),
			multi: true,
		}
	]
})
export class MultiLanguageComponent implements ControlValueAccessor, Validator {

	private _value: any;

	get value() {
		return this._value;
	}
	set value(val: any) {
		this._value = val;
	}

	@Input()
	required: boolean;

	@Input()
	languages: any[] = ['slo', 'eng'];

	@Input()
	disabled = false;

	@Input()
	name: string;


	@HostBinding('style.opacity')
	get opacity() {
		return this.disabled ? 0.25 : 1;
	}

	// Function to call when the rating changes.
	onChange = (obj: any) => { };

	// Function to call when the input is touched (when a star is clicked).
	onTouched = () => { };


	constructor() {
	}

	writeValue(obj: any): void {
		if (obj === undefined || obj === null) {
			this.value = {};
		} else {
			this.value = obj;
		}
		this.onChange(this.value);

	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	// returns null when valid else the validation object
	// in this case we're checking if the json parsing has
	// passed or failed from the onChange method
	public validate(c: FormControl) {
		if (!this.required) {
			return null;
		}
		if (!this.value || this.translationsEmpty()) {
			return { required: { valid: false } };
		}
		return null;
	}

	setTranslation(translation: string, lng: any) {
		this.value[lng] = translation;
		this.writeValue(this.value);
	}

	getTranslation(lng: any) {
		if (this.value[lng] === undefined || !this.value[lng]) {
			return '';
		}
		return this.value[lng];
	}

	private translationsEmpty() {
		if (!this.value) {
			return true;
		}
		const keys = Object.keys(this.value);

		if (!keys.length) {
			return true;
		}

		for (const key of keys) {
			if (this.value[key]) {
				return false;
			}
		}
		return true;
	}

}
