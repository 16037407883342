import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SearchCheckerService {
	public showSearchComponent = true;

	constructor() { }
}
