import { Pipe, PipeTransform, Inject } from '@angular/core';
import { TranslateConfig, TRANSLATE_CONFIG } from '../modules/config/app-configuration';

@Pipe({
	name: 'translate'
})
export class TranslatePipe implements PipeTransform {

	constructor(@Inject(TRANSLATE_CONFIG) private translateConfig: TranslateConfig){}
	transform(key: any): any {
		if (!this.translateConfig.USE_TRANSLATIONS) {
			if (!this.translateConfig.BASE_LABELS[key]) {
				return new Error(`Missing key (${key}) for BASE_LABELS - translation pipe`);
			}
			return this.translateConfig.BASE_LABELS[key];
		}
		return new Error('Translate module for translation pipe not implemented yet');
	}

}
