import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ConfigBasedComponent } from 'src/app/classes/base/ConfigBasedComponent';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePassword } from 'src/app/interface/Auth';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends ConfigBasedComponent {

	changePwd: ChangePassword;

	@ViewChild('changePasswordForm')
	form: NgForm;

	changed: boolean;

	constructor(
		private auth: AuthService,
		inj: Injector
	) {
		super(inj);
		this.resetForm();
	}

	changePassword() {
		this.auth.changePassword(this.changePwd)
			.then(() => this.changed = true);
	}

	resetForm() {
		this.changePwd = {};
		if (this.form) {
			this.form.resetForm();
		}
	}
}
