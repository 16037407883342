import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class CreateAccTokenResolve implements Resolve<boolean> {

	constructor(
		private authService: AuthService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const token: string = route.paramMap.get('token');
		return this.authService.validateAppInviteToken(token);
	}
}
