import { trigger, transition, style, animate, query, group, stagger } from '@angular/animations';

export class ApplicationAnimations {
	static fadeIn(animateTime = '0.5s') {
		return trigger('fadeIn', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(animateTime, style({ opacity: 1 }))
			])
		]);
	}

	static fadeInOut(animateTime = '0.5s') {
		return trigger('fadeInOut', [
			transition('* <=> *', [
				query(':enter', style( {left: 0, right: 0, opacity: 0, position: 'absolute' }), {optional: true}),
				query(':leave', [
					style({ left: 0, right: 0, opacity: 1, position: 'relative' }),
					animate(animateTime, style({ opacity: 0 })),
					style({position: 'absolute'})
				], {optional: true}),
				query(':enter', style({position: 'relative'}), {optional: true}),
				query(':enter', animate(animateTime, style({ opacity: 1 })), {optional: true})
			])
		]);
	}

	static fadeInList(staggerTime = 150, animateTime = '0.5s') {
		return trigger('listAnimation', [
			transition('* => *', group([ // each time the binding value changes
				query(':enter', [
					style({ opacity: 0 })
				], { optional: true }),
				query(':enter', [
					stagger(staggerTime, [
						animate(animateTime, style({ opacity: 1 }))
					])
				], { optional: true })
			]))
		]);
	}

	static slideInOut(animateTime = '0.5s') {
		return trigger('slideInOut', [
			transition(':enter', [
				style({ height: '0px', opacity: 0, overflow: 'hidden' }),
				animate(animateTime, style({ height: '*', opacity: 1 })),
				style({ overflow: 'auto' })
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate(animateTime, style({ height: '0px', opacity: 0 })),
				style({ overflow: 'auto' })
			])
		]);
	}
}
