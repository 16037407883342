import { ConfirmationService } from 'primeng/primeng';
import { BaseInjector } from '../classes/BaseInjector';
import { TranslateService } from 'arctur-translate-all';

export function ArcturConfirm(
	options: {
		header?: string,
		message?: string,
		translateHeader?: string,
		translateMessage?: string
	}
): MethodDecorator {
	return function(target: Function, key: string, descriptor: any) {

		const originalMethod = descriptor.value;
		descriptor.value = function(...args: any[]) {
			// tukaj bo injector dostopen ker je šele klic metode
			// če bi bil izven klica funkcije bi bil undefined
			const confirmService = BaseInjector.injector.get(ConfirmationService);
			const translateService = BaseInjector.injector.get(TranslateService);
			let _header = 'Confirm';
			let _message = 'Are you sure to proceed?';
			if (options.header || options.translateHeader) {
				_header = options.translateHeader ? translateService.lng[options.translateHeader] : options.header;
			}
			if (options.message || options.translateMessage) {
				_message = options.translateMessage ? translateService.lng[options.translateMessage] : options.message;
			}
			confirmService.confirm({
				header: _header,
				message: _message,
				accept: () => originalMethod.apply(this, args)
			});
		};
	};
}
