import { Component, OnInit, EventEmitter, Output, Input, Injector } from '@angular/core';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { RadioItem } from 'src/app/classes/base/RadioItem';

@Component({
	selector: 'app-input-checkbox',
	templateUrl: './input-checkbox.component.html',
	styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit {

	private _value: any;

	@Output()
	valueChange = new EventEmitter<any>();

	@Input()
	set value(val: any) {
		this._value = val;
		this.valueChange.emit(val);
	}

	get value() {
		return this._value;
	}

	@Input()
	name: string;

	@Input()
	label: string;

	@Input()
	feed: RadioItem[];

	@Input()
	disabled: boolean;

	@Input()
	required: boolean;

	@Input()
	showLabel = false;

	constructor() {
	}

	ngOnInit() {
	}

	getLabel(oItem: CheckItem) {
		return oItem.value;
	}

}
