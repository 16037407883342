import { Component, Injector, Input } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Component({
	selector: 'app-input-error',
	templateUrl: './input-error.component.html',
	styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent {

	@Input()
	input: NgModel;

	get showError() {
		return this.input && (this.input.invalid && (this.input.touched || this.form.submitted));
	}

	constructor(private form: NgForm) {
	}

	getFieldName(key: string) {
		return `form_error_${key}`;
	}

}
