import { Component, Injector, OnInit } from '@angular/core';
import { ConfigBasedComponent } from 'src/app/classes/base/ConfigBasedComponent';
import { AllowCookiesService } from 'src/app/services/allow-cookies.service';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
	selector: 'app-allow-cookies',
	templateUrl: './allow-cookies.component.html',
	styleUrls: ['./allow-cookies.component.scss'],
	animations: [
		trigger('cookieShowAnimation', [
			transition(':enter', [
				style({ opacity: 0,  height: '0'}),
				animate('0.3s', style({ opacity: 1, height: '*' }))
			]),
			transition(':leave', [
				style({height: '*'}),
				animate('0.3s', style({ opacity: 0, height: '0' }))
			])
		])
	]
})
export class AllowCookiesComponent extends ConfigBasedComponent implements OnInit {


	delayed: boolean;

	constructor(
		public allowCookies: AllowCookiesService,
		inj: Injector
	) {
		super(inj);
	}

	ngOnInit(): void {
		setTimeout(() => this.delayed = true, 150);
	}


	toggle() {
		this.allowCookies.toggle();
	}

	accept() {
		this.allowCookies.accept();
	}

	decline() {
		this.allowCookies.decline();
	}
}
