import { Component, OnInit, Input } from '@angular/core';
import { CacheService } from 'src/app/services/cache.service';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
	selector: 'app-stored-uploader',
	templateUrl: './stored-uploader.component.html',
	styleUrls: ['./stored-uploader.component.scss']
})
export class StoredUploaderComponent implements OnInit {
	@Input()
	userData: string;
	processing = false;

	constructor(
		private cacheService: CacheService,
		private contactService: ContactsService
	) { }

	ngOnInit() {
	}

	uploadImages() {
		const cachedImages = this.cacheService.scanedImages;
		if(cachedImages.length > 0){
			this.processing = true;
		}
		cachedImages.forEach((el,index) => {
			this.contactService.uploadImages(el).then(data => {
				this.cacheService.notEditedImages.notEditedList.push(data);
				this.cacheService.removeScanedImage(index);
			})
		});
	}

}
