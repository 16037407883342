import { Injectable } from '@angular/core';
import { JWT } from '../classes/JWT';
import { UserService } from './user.service';
import { LocalStorage } from 'ngx-webstorage';

@Injectable({
	providedIn: 'root'
})
export class JwtService {

	// localstorage properties
	@LocalStorage('jwt')
	private _jwt: string;
	public get jwt(): string {
		return this._jwt;
	}
	public set jwt(val: string) {
		this._jwt = val;
		this._jwtObject = val ? new JWT(val) : null;
		this.userService.setUser(val);
	}

	// jwt object
	private _jwtObject: JWT;
	public get jwtObject(): JWT {
		return this._jwtObject;
	}
	public set jwtObject(val: JWT) {
		this._jwtObject = val;
	}

	constructor(private userService: UserService) {
		if (this.jwt) {
			this.jwtObject = new JWT(this.jwt);
		}
	}

}
