import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseParentComponent } from '../classes/base/BaseParentComponent';

@Injectable({
	providedIn: 'root'
})
export class BaseAppService {

	private _baseParentComponent: BaseParentComponent = null;

	set baseParentComponent(component: BaseParentComponent) {
		this._baseParentComponent = component;
	}
	get baseParentComponent() {
		return this._baseParentComponent;
	}

	// here you implement things you need across whole application
	constructor() {}


}
