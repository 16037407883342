export class JWT {

	private _jwt: string;
	public get jwt(): string {
		return this._jwt;
	}
	public set jwt(v: string) {
		this._jwt = v;
	}

	private _payload: any;
	public get payload(): any {
		return this._payload;
	}
	public set payload(v: any) {
		this._payload = v;
	}


	// constructor
	constructor(jwt?: string) {
		if (jwt) {
			this.jwt = jwt;
			// split by '.'
			const split: string[] = jwt.split('.');
			this.payload = JSON.parse(atob(split[1]));
		}
	}

	checkIfExpired() {

		// check if payload and exp exists
		if (!this.payload) {
			return false;
		}
		if (!this.payload.exp) {
			return false;
		}

		// create from exp a new date object and compare it to current
		const expDate = new Date(this.payload.exp);
		if (expDate < new Date()) {
			return false;
		}
		return true;

	}

}
