import { NgModule } from '@angular/core';
import { SlashIfEmptyPipe } from 'src/app/pipes/slash-if-empty.pipe';
import { KeepHtmlPipe } from 'src/app/pipes/keep-html.pipe';
import { CutAtPipe } from 'src/app/pipes/cut-at.pipe';
import { CapacityUnitPipe } from 'src/app/pipes/capacity-unit.pipe';
import { ObjectKeysPipe } from 'src/app/pipes/object-keys.pipe';
import { InputErrorKeyPipe } from 'src/app/pipes/input-error-key.pipe';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { FileSizePipe } from 'src/app/pipes/file-size.pipe';

@NgModule({
	imports: [
	],
	declarations: [
		CapacityUnitPipe,
		CutAtPipe,
		KeepHtmlPipe,
		SlashIfEmptyPipe,
		ObjectKeysPipe,
		InputErrorKeyPipe,
		TranslatePipe,
		FileSizePipe
		
	],
	exports: [
		CapacityUnitPipe,
		CutAtPipe,
		KeepHtmlPipe,
		SlashIfEmptyPipe,
		ObjectKeysPipe,
		InputErrorKeyPipe,
		TranslatePipe,
		FileSizePipe
	]
})
export class PipesModule { }
