import { Injectable, Injector } from '@angular/core';
import { ConfigBasedHttpService } from '../classes/base/ConfigBasedService';
import { ApiResponse } from 'src/app/interface/ApiResponse';
import { JwtService } from 'src/app/services/jwt.service';
import { Router } from '@angular/router';
import { UserLogin } from 'src/app/classes/Auth';
import { tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { CreateAccount, ChangePassword } from 'src/app/interface/Auth';

@Injectable({
	providedIn: 'root'
})
export class AuthService extends ConfigBasedHttpService {

	public referal;

	constructor(
		private userService: UserService,
		private router: Router,
		private jwtService: JwtService,
		inj: Injector
	) {
		super(inj);
		this.userService.setUser(this.jwtService.jwt);
	}

	// public get jwt
	getJwtToken(): string {
		return this.jwtService.jwt;
	}

	// login user -> returns JWT
	login(userLogin: UserLogin) {
		return this.http.post<string>(`${this.config.API_PATH}/auth/login`, userLogin)
			.toPromise()
				.then((res: string) => {
					if (this.referal) {
						this.router.navigate(this.referal.url, {queryParams: this.referal.params});
						this.referal = null;
					} else {
						this.router.navigate(['/']);
					}
					return res;
				});
	}

	// create account
	createAccount(acc: CreateAccount) {
		return this.http.post<ApiResponse>(`${this.config.API_PATH}/auth/create-account`, acc)
			.toPromise();
	}


	// logout
	logout() {
		this.jwtService.jwt = null;
		this.userService.current = null;
		this.router.navigate(['/auth/login']);
	}

	// forgot password path
	forgotPassword(email) {
		return this.http.post<ApiResponse>(`${this.config.API_PATH}/auth/forgot-password`, { email })
			.toPromise();
	}

	// change password action
	changePassword(chgPassword: ChangePassword) {
		return this.http.post<ApiResponse>(`${this.config.API_PATH}/auth/change-password`, chgPassword)
			.pipe(
				tap(() => {
					// reset jwt from forgot pwd
					this.jwtService.jwt = null;
					this.router.navigate(['/']);
				})
			)
			.toPromise();
	}

	// validate jwt
	validateJwt(): Promise<any> {
		return this.http.get<ApiResponse>(`${this.config.API_PATH}/auth/validate-jwt`)
			.toPromise();
	}

	// validat app invite token
	validateAppInviteToken(token: string): Promise<any> {
		return this.http.get<ApiResponse>(`${this.config.API_PATH}/auth/validate-app-token/${token}`)
			.toPromise();
	}


	public setRef(route): void {
		const url = (<any> route)._urlSegment.segments.map(seg => seg.path);
		url.unshift('/');
		this.referal = {
			url: url,
			params: route.queryParams
		};
	}

}
