import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-base-admin',
	templateUrl: './base-admin.component.html',
	styleUrls: ['./base-admin.component.scss']
})
export class BaseAdminComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
