import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Contacts } from 'src/app/classes/crm/contacts';
import { ContactsService } from 'src/app/services/contacts.service';
import { CheckItem } from 'src/app/classes/base/CheckItem';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'primeng/components/common/messageservice';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ArcturConfirm } from 'src/app/decorators/ArcturConfirm';
import { CacheService } from 'src/app/services/cache.service';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { VCard } from 'ngx-vcard';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterViewInit {
	ngAfterViewInit(): void {
		console.log(this.contactsSearch$);
	}
	contacts: Contacts[];
	contactsSearch$: Observable<Contacts[]>;
	contactDetail: Contacts;
	selectedContacts = [];
	layoutType = 'list';
	baseUrl = '/crm/contacts';
	displayDialog = false;
	selectedCategories: CheckItem[];
	filterCategory: string;
	categoryFeed: CheckItem[];
	contactCategory: any;
	showBigPhoto = true;
	paginator = false;
	showList = true;
	showDisplaySitcher = true;
	searchValue = '';

	deleted: any[] = [];
	refresh$ = new BehaviorSubject(true);

	constructor(
		private contactsService: ContactsService,
		private clipboardService: ClipboardService,
		private messageService: MessageService,
		private deviceService: DeviceDetectorService,
		private cacheService: CacheService,
	) {

	}

	ngOnInit() {
		if (this.deviceService.isMobile()) {
			this.showBigPhoto = false;
			this.showList = false;
			this.showDisplaySitcher = false;
		}
		/*
		this.contactsService.getContacts('')
			.then(
				(contacts: Contacts[]) => {
					this.contacts = contacts;
				}
			);
		*/
		this.contactsService.getCategory()
			.then(
				(category: CheckItem[]) => {
					this.categoryFeed = category;
				}
			);
		this.contactsService.getContactCategory()
			.then(
				(contactCategorsy: any) => {
					this.contactCategory = contactCategorsy;
				}
			);
		this.contactsSearch$ = combineLatest(
			this.refresh$,
			this.cacheService.searchResults$
		).pipe(
			map(([refresh, el]) => {
				return el.filter(e => this.deleted.indexOf(e.id) === -1)
			})
		);
	}

	@ArcturConfirm({
		header: 'Brisanje kontakta',
		message: 'Ste prepričani?',
	})
	delete(id: string) {


		this.contactsService.delete(id)
			.then(() => {

				this.deleted.push(id);
				this.refresh$.next(true);
			})
	}

	onDialogHide() {
		this.selectedContacts = null;
	}

	getVcard(){
		const VCard: VCard = {};
	}


	getContactCategory(id: string) {
		let category = '';
		if (this.contactCategory[id]) {
			Object.keys(this.contactCategory[id]).map((key) => {

				if (this.contactCategory[id][key]) {
					if (category != '') {
						category += ', ';
					}
					category += this.contactCategory[id][key].title;
				}
			})

		}

		return category;
	}

	getCategory() {
		let category = '';
		if (this.selectedCategories) {
			this.selectedCategories.forEach(el => {
				if (category != '') {
					category += ', ';
				}
				category += el.value;
			})
		}
		return category;
	}

	selectContact(event: Event, contact: Contacts) {
		this.contactDetail = contact;
		this.displayDialog = true;
		event.preventDefault();
	}

	copyContact(contact: Contacts) {
		const copyContact = contact.contact + "\n" + contact.email + "\n" + contact.gsm + "\n" + contact.company;
		this.clipboardService.copyFromContent(copyContact);
		this.messageService.add({
			severity: 'success',
			summary: 'Podatki o kontaktu shranjeni v odložišče'
		});
	}

	showBigImage(event: any, el: any) {
		if (!this.deviceService.isMobile()) {
			el.toggle(event);
		}

	}

	onFilterByCategory(el: any) {

		this.contactsService.getContactsByCategory(this.selectedCategories)
			.then(
				(contacts: Contacts[]) => {
					// this.contacts = contacts;
					this.paginator = true;
					// FIXME: this.contactsSearch = contacts;

				}
			);
		if (this.selectedCategories.length === 0 && this.searchValue === '') {
			// this.contactsSearch = [];
			this.paginator = false;
		}
		if (this.searchValue !== '') {
			el.filter(this.searchValue);
		}


	}

	dataFilter(event: any, el: any) {

		this.paginator = true;
		if (this.selectedCategories === undefined || this.selectedCategories.length === 0) {
			// FIXME: this.contactsSearch = this.contacts;
		}
		el.filter(event.target.value);
		if (event.target.value === '') {
			if (this.selectedCategories === undefined || this.selectedCategories.length === 0) {
				// FIXME: this.contactsSearch = [];
				this.paginator = false;
			}
		}
	}

}

